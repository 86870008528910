import { IGenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';

const updateCreateCompany = async (variables: any): Promise<IGenericResponse> => {
    const resp = await apiRQ[getHttpMethod(variables?.id)]<IGenericResponse>('/admin/companies/', { data: variables });

    return resp.data;
};

export const useUpdateCreateCompany = () => {
    return useMutation<IGenericResponse, Error>(updateCreateCompany);
};

const deleteCompany = async (data: any): Promise<IGenericResponse> => {
    const resp = await apiRQ.delete<IGenericResponse>('/admin/companies/', { data });

    return resp.data;
};

export const useDeleteCompany = () => {
    return useMutation<IGenericResponse, Error>(deleteCompany);
};
