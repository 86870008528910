import * as React from 'react';
import { IonImg } from '@ionic/react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../../theme/assets/logo.png');

export const LoadingScreen = () => {
  return (
    <div style={styles.container}>
      <IonImg src={logo} style={styles.logo} />
    </div>
  );
};

const styles = {
  container: {
    background: '#c1cad0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  logo: {
    width: 250,
    height: 150,
    animation: 'fadeIn 1.2s infinite',
    zIndex: 101,
  },
};
