import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import React from 'react';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import { appPagesMenu } from '../utils/navigationData';
import { useSelector } from 'react-redux';
import { selectUserFullName } from '../store/user/userSlice';

const Menu: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const userFullName = useSelector(selectUserFullName);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <div className="mediumText5 margin-t-minus-10 padding-l-10">{userFullName}</div>
        <IonList id="inbox-list pad-t-0">
          {appPagesMenu.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={location.pathname === appPage.url ? 'selected' : ''}
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{t(appPage.title)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
