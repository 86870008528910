import * as React from 'react';
import { useEffect } from 'react';
import { IonFabButton, IonIcon, useIonAlert } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { IGenericResponse } from '../../interfaces/shared.interface';
import { GeotrianLoading } from './loading/GeotrianLoading';
import { setToast } from '../../store/core/coreSlice';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import { selectIsSuperuser } from '../../store/user/userSlice';

interface Props {
  submitData: Record<string, string | number | undefined>;
  deleteHook: () => UseMutationResult<IGenericResponse, Error, void, unknown>;
}

export const DeleteIconButton = ({ submitData, deleteHook }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const { mutate: deleteEntity, isLoading, isSuccess, data: resp } = deleteHook();
  const isSuperuser = useAppSelector(selectIsSuperuser);

  if (!isSuperuser) return null;

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
      dispatch(
        setToast({
          value: { show: true, msg: resp?.error || t('SHARED.DELETE_SUCCESSFUL'), isError: !resp?.success || false },
        }),
      );
    }
  }, [isSuccess]);

  const showConfirmationDialog = () => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            deleteEntity(submitData);
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  };

  const onClick = () => {
    console.log('onClick');
    showConfirmationDialog();
  };

  return (
    <>
      <div className="deleteIcon flex-center-center-row-wrap" onClick={(e) => e.stopPropagation()}>
        <IonFabButton size="small" color="light" onClick={onClick}>
          <IonIcon icon={trashOutline} color="danger"></IonIcon>
        </IonFabButton>
      </div>
      {isLoading && <GeotrianLoading />}
    </>
  );
};
