import * as React from 'react';

interface Props {
  id: number | string;
}

export const EntityIdInCard = ({ id }: Props) => {
  return (
    <div className="flex-center-center-column-nowrap" style={{ position: 'relative' }}>
      <div className="text-shade normalText4" style={{ position: 'absolute', top: -20, left: -6 }}>
        <b>({id})</b>
      </div>
    </div>
  );
};
