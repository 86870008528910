import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IDocument } from '../../../interfaces/documents.inteface';

const getDoc = async (id: number): Promise<IDocument> => {
  const resp = await apiRQ.get<IDocument>(`/admin/documents/${id}`);

  return resp.data;
};

export const useDoc = (id: number | string) => {
  return useQuery<IDocument, Error>(['getDoc', id], () => getDoc(Number(id)), {
    enabled: id !== 'create',
  });
};
