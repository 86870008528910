import * as React from 'react';
import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ErrorForm } from '../../../components/shared/forms/ErrorForm';
import * as yup from 'yup';
import i18next from 'i18next';
import { useAppDispatch } from '../../../store/hooks';
import { loginUserAsync } from '../../../store/user/userActions';

interface ILoginForm {
  username: string;
  password: string;
}

const initialFormData: ILoginForm = { username: '', password: '' };

const validationSchema = yup.object({
  username: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_USERNAME')),
  password: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_PASSWORD')),
});

export const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      values.username = values.username.trim();
      dispatch(loginUserAsync(values));
    },
  });

  const onKeyUp = (event: any) => {
    // Submit form on enter
    if (event.nativeEvent.key === 'Enter') {
      if (formik.isValid) {
        formik.handleSubmit();
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} onKeyUp={($event) => onKeyUp($event)}>
      <div className="itemBackground" style={styles.container}>
        <IonItem>
          <IonLabel position="floating">{t('FORMS.USERNAME')}</IonLabel>
          <IonInput
            name="username"
            onIonChange={formik.handleChange}
            value={formik.values.username}></IonInput>
        </IonItem>
        <ErrorForm>{formik.errors.username && formik.touched.username && formik.errors.username}</ErrorForm>
        <IonItem>
          <IonLabel position="floating">{t('FORMS.PASSWORD')}</IonLabel>
          <IonInput
            name="password"
            type="password"
            onIonChange={formik.handleChange}
            value={formik.values.password}
            enterkeyhint="go"></IonInput>
        </IonItem>
        <ErrorForm>{formik.errors.password && formik.touched.password && formik.errors.password}</ErrorForm>
        <div style={styles.loginButton}>
          <IonButton shape="round" type="submit" disabled={!formik.isValid}>
            {t('FORMS.LOGIN')}
          </IonButton>
        </div>
      </div>
    </form>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 380,
    height: 300,
    borderRadius: 25,
  },
  loginButton: {
    marginTop: 16,
  },
};
