import * as React from 'react';
import { getNotificationsSortByFilter } from '../../utils/data';
import { SORT_BY_NOTIFICATIONS, SORT_DIRECTION } from '../../interfaces/enumerables';
import { useNotifications } from '../../api/hooks/notifications/useNotifications';
import { NotificationItemCard } from './components/NotificationItemCard';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';

export const NotificationsScreen = () => {
  const notificationsSortByFilter = getNotificationsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useNotifications}
      ItemCardData={NotificationItemCard}
      sortByFilter={notificationsSortByFilter}
      sortByDefaultKey={SORT_BY_NOTIFICATIONS.ID}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
