import * as React from 'react';
import { useCallback } from 'react';
import { IExpense } from '../../../interfaces/expenses.interface';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { getLocalDate } from '../../../utils/helpers';
import { TypePill } from '../../../components/shared/ItemCard/TypePill';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';
import { PATHS } from '../../../utils/data';
import { CloneEntityButton } from '../../../components/shared/CloneEntityButton';

interface Props {
  data: IExpense;
}

export const ExpenseItemCard = ({ data }: Props) => {
  const getOwner = useCallback(() => {
    if (data.company) {
      return (
        <LinkTo link={`/companies/${data.company?.id}`}>
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.company?.title || ''} />
        </LinkTo>
      );
    }

    return (
      <LinkTo link={`/clients/${data.client?.id}`}>
        <ItemCardField
          classes="margin-r-24"
          width="width-180px"
          value={data?.client?.firstName || data?.client?.lastName || ''}
        />
      </LinkTo>
    );
  }, [data.company, data.client]);

  return (
    <ItemCard
      classes={data?.done ? 'border-success' : 'border-error'}
      entityId={data.id}
      routerLink={`${PATHS.EXPENSES}/${data.id}`}>
      <>
        <CloneEntityButton data={data} path={PATHS.EXPENSES} />
        <TypePill type={data?.typeOf?.typeOf} />
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24" width="width-80px" value={data?.title} />
          {getOwner()}
          <div className="margin-r-24 width-80px">{data?.value} €</div>
          <div>
            <div className="text-danger">{getLocalDate(data?.deadline)}</div>
            {data?.done && <div className="text-success">{getLocalDate(data?.doneDate)}</div>}
          </div>
        </div>
      </>
    </ItemCard>
  );
};
