import * as React from 'react';
import { IonImg } from '@ionic/react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../../theme/assets/logo.png');

export const GeotrianLoading = () => {
  return (
    <div className="full-screen">
      <div className="loader-body">
        <IonImg src={logo} style={styles.logo} />
      </div>
    </div>
  );
};

const styles = {
  logo: {
    width: 250,
    height: 150,
    animation: 'fadeIn 1.2s infinite',
  },
};
