import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { getLocalDate } from '../../../utils/helpers';
import * as React from 'react';
import { useCallback } from 'react';
import { IDocument } from '../../../interfaces/documents.inteface';
import { IonIcon } from '@ionic/react';
import { documentOutline } from 'ionicons/icons';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';
import { PATHS } from '../../../utils/data';

interface Props {
  data: IDocument;
}

export const DocItemCard = ({ data }: Props) => {
  const getOwner = useCallback(() => {
    if (data.company) {
      return (
        <LinkTo link={`/companies/${data.company?.id}`}>
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.company?.title || ''} />
        </LinkTo>
      );
    }

    return (
      <LinkTo link={`/clients/${data.client?.id}`}>
        <ItemCardField
          classes="margin-r-24"
          width="width-180px"
          value={data?.client?.firstName || data?.client?.lastName || ''}
        />
      </LinkTo>
    );
  }, [data.company, data.client]);

  return (
    <ItemCard entityId={data.id} routerLink={`${PATHS.DOCUMENTS}/${data.id}`}>
      <div className="flex-space-between-center-row-wrap width-100">
        <ItemCardField classes="margin-r-24" width="width-880px" value={data?.title || ''} />
        {getOwner()}
        <div className="margin-r-24 width-180px">{getLocalDate(data?.pubDate)}</div>
        <div className="flex-center-center-row-wrap" onClick={(e) => e.stopPropagation()}>
          <a href={data?.file || ''} target="_blank" rel="noreferrer">
            <IonIcon icon={documentOutline} style={{ fontSize: 24 }}></IonIcon>
          </a>
        </div>
      </div>
    </ItemCard>
  );
};
