import { ILoginResponse, LoginDTO } from '../interfaces/auth.interface';
import { api } from '../api/api';
import { INotificationToken } from '../interfaces/notification.interface';
import { IGenericResponse } from '../interfaces/shared.interface';

export const loginService = async (userData: LoginDTO): Promise<ILoginResponse> => {
    const resp = await api.post<ILoginResponse>('/auth/jwt-auth', userData);

    // Check if an error occurred and caught from interceptor
    if (resp?.data?.thrownError) {
        return { success: false };
    }

    const { user } = resp.data;

    return { success: true, user };
};

export const logoutService = async (notificationToken: INotificationToken | null): Promise<IGenericResponse> => {
    const resp = await api.post<IGenericResponse>('auth/logout', { ...notificationToken });

    // Check if an error occurred and caught from interceptor
    if (resp?.data?.thrownError) {
        return { success: false };
    }

    return { success: true };
};
