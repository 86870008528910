import { TextFieldTypes } from '@ionic/core';
import { UseQueryResult } from 'react-query';

export enum INPUT_TYPE {
  SELECT_SEARCH = 'selectSearch',
  MULTI_SELECT_SEARCH = 'multiSelectSearch',
  TOGGLE = 'toggle',
  DATE = 'date',
  SELECT = 'select',
  TEXT_AREA = 'textArea'
}

export interface IEntityFormField {
  name: string; // to use in forms
  label: string; // translation
  placeholder: string;
  value: string | number | any;
  type: TextFieldTypes | INPUT_TYPE;
  disabled?: boolean;
  fieldToSubmit?: any;
  fieldMap?: (data: any[]) => any;
  fieldValue?: string;
  fieldValue2?: string;
  useHook?: (
    page: number,
    orderBy: string | undefined,
    order: string | undefined,
    search: string,
  ) => UseQueryResult<any, Error>;
  data?: {
    label: string;
    value: number;
  }[];
}

export interface IEntityForm {
  id?: number | string;
  id2?: number | string;
  fields: IEntityFormField[];
  onlyViewFields?: IEntityFormField[];
}
