import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES, translations } from './common';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },
    resources: translations,
    lng: LANGUAGES.EL,
    fallbackLng: LANGUAGES.EL,
  });
