import * as React from 'react';
import { CompanyItemCard } from './components/CompanyItemCard';
import { getCompaniesSortByFilter } from '../../utils/data';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { SORT_BY_COMPANIES } from '../../interfaces/enumerables';
import { useCompanies } from '../../api/hooks/companies/useCompanies';

export const CompaniesScreen = () => {
  const companiesSortByFilter = getCompaniesSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useCompanies}
      ItemCardData={CompanyItemCard}
      sortByFilter={companiesSortByFilter}
      sortByDefaultKey={SORT_BY_COMPANIES.ID}
    />
  );
};
