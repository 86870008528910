import * as React from 'react';
import { useParams } from 'react-router';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { usePayment } from '../../api/hooks/payments/usePayment';
import { IPayment } from '../../interfaces/payments.interface';
import { useDeletePayment, useUpdateCreatePayment } from '../../api/hooks/payments/useUpdateCreatePayment';
import { useClients } from '../../api/hooks/clients/useClients';
import { useExpenses } from '../../api/hooks/expenses/useExpenses';
import { getLocalDate, nowDate } from '../../utils/helpers';

const getFormData = (id?: number | string, data?: IPayment): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'value',
        label: 'VALUE',
        placeholder: 'VALUE',
        value: data?.value || '',
        type: 'text',
      },
      {
        name: 'payDate',
        label: 'PAY_DATE',
        placeholder: 'PAY_DATE',
        value: data?.payDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'outgoingId',
        label: 'OUTGOING',
        placeholder: 'OUTGOING',
        value: data?.outgoing || '',
        fieldToSubmit: 'id',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'id',
        fieldValue2: 'title',
        useHook: useExpenses,
      },
      {
        name: 'userId',
        label: 'CLIENT',
        placeholder: 'CLIENT',
        value: data?.user || '',
        fieldToSubmit: 'userId',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'firstName',
        fieldValue2: 'username',
        useHook: useClients,
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  value: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_VALUE')),
  outgoingId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).nullable(),
  userId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).nullable(),
});

export const PaymentScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = usePayment(id);

  return (
    <div>
      <EntityForm
        data={getFormData(id, data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreatePayment}
        deleteHook={useDeletePayment}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};