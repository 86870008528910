import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../../utils/data';
import { useHistory } from 'react-router-dom';

interface Props {
  title: string;
  navigateTo: PATHS;
}

export const NavigationCard = ({ title, navigateTo }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => {
    if (navigateTo) {
      history.push(navigateTo);
    }
  };

  return (
    <div className="width-140px-simple" onClick={onClick}>
      <div className="navCard flex-center-center-column-nowrap mediumText3">{t(title)}</div>
    </div>
  );
};
