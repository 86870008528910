import { ENV } from '../config/env';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { netCheck, onFulfilled, onRejected } from './middleware';
import { store } from '../store/store';
import { logoutUserAsync } from '../store/user/userActions';
import { setToast } from '../store/core/coreSlice';

export const api = axios.create({
  baseURL: ENV.API_URL,
});

export const apiRQ = axios.create({
  baseURL: ENV.API_URL,
});

api.interceptors.request.use(onFulfilled, onRejected);

apiRQ.interceptors.request.use(onFulfilled, onRejected);

// Cancel request if there is no internet
api.interceptors.request.use(netCheck, onRejected);
apiRQ.interceptors.request.use(netCheck, onRejected);

// Add a response interceptor for errors
api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.error && !response.data.success) {
      store.dispatch(setToast({ value: { show: true, msg: response.data.error, isError: true } }));
    }

    return response;
  },
  (err: AxiosError) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.dispatch(setToast({ value: { show: true, msg: err?.response?.data?.error, isError: true } }));

    if (err?.response?.status === 401) {
      store.dispatch(logoutUserAsync());
      // store.dispatch(setError({ value: { show: true, msg: err?.response?.data?.error } }));
      // ShowAlert({
      //   message: i18n.t('ERRORS.SOMETHING_WENT_WRONG'),
      //   description: i18n.t('ERRORS.' + err?.response?.data?.error) || i18n.t('ERRORS.UNAUTHORIZED'),
      //   type: 'danger',
      // });
      return { data: { thrownError: true } };
    }

    if (err?.response?.status === 404) {
      // ShowAlert({
      //   message: i18n.t('ERRORS.SOMETHING_WENT_WRONG'),
      //   description: i18n.t('ERRORS.' + err?.response?.data?.error) || '',
      //   type: 'danger',
      // });
      return { data: { thrownError: true } };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (err?.response?.data?.error) {
      // ShowAlert({
      //   message: i18n.t('ERRORS.SOMETHING_WENT_WRONG'),
      //   description: i18n.t('ERRORS.' + err?.response?.data?.error),
      //   type: 'danger',
      // });
      return { data: { thrownError: true } };
    }

    // Request cancelled by code (Case 1: NO_INTERNET)
    if (err?.message && !err?.response?.data) {
      // ShowAlert({
      //   message: i18n.t('ERRORS.SOMETHING_WENT_WRONG'),
      //   description: i18n.t(err?.message),
      //   type: 'danger',
      // });
      return { data: { thrownError: true } };
    }

    // ShowAlert({
    //   message: i18n.t('ERRORS.SOMETHING_WENT_WRONG_CONTACT_ADMIN'),
    //   type: 'danger',
    // });
    return { data: { thrownError: true } };
  },
);
