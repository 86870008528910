import * as React from 'react';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { IPayment } from '../../../interfaces/payments.interface';
import { useTranslation } from 'react-i18next';
import { getLocalDate } from '../../../utils/helpers';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';
import { PATHS } from '../../../utils/data';

interface Props {
  data: IPayment;
}

export const PaymentItemCard = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <ItemCard entityId={data.id} routerLink={`${PATHS.PAYMENTS}/${data.id}`}>
      <div className="flex-space-between-center-row-wrap width-100">
        <LinkTo link={`/expenses/${data?.outgoingId}`}>
          <div className="margin-r-24 width-100px">{t('SHARED.EXPENSE_ID', { expenseId: data.outgoingId })}</div>
        </LinkTo>
        <div className="margin-r-24 width-100px">{data?.user?.username}</div>
        <div className="margin-r-24 width-80px">{data?.value} €</div>
        <div>{getLocalDate(data?.payDate)}</div>
      </div>
    </ItemCard>
  );
};
