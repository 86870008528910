import * as React from 'react';
import { IonButton } from '@ionic/react';

interface Props {
  value: string;
  onClick: () => void;
}

export const ClickablePill = ({ value, onClick }: Props) => {
  return (
    <IonButton fill="solid" shape="round" onClick={onClick} className="margin-r-4">
      <div className="clickablePill">{value}</div>
    </IonButton>
  );
};
