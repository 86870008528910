import * as React from 'react';
import { getTypeOfSortByFilter } from '../../utils/data';
import { SORT_BY_TYPE_OF } from '../../interfaces/enumerables';
import { TypeItemCard } from './components/TypeItemCard';
import { useTypes } from '../../api/hooks/types/useTypes';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';

export const TypesScreen = () => {
  const typeOfSortByFilter = getTypeOfSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useTypes}
      ItemCardData={TypeItemCard}
      sortByFilter={typeOfSortByFilter}
      sortByDefaultKey={SORT_BY_TYPE_OF.ID}
    />
  );
};
