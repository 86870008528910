import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IEmail } from '../../../interfaces/emails.interface';

const getEmail = async (id: number): Promise<IEmail> => {
  const resp = await apiRQ.get<IEmail>(`/admin/emails/${id}`);

  return resp.data;
};

export const useEmail = (id: number | string) => {
  return useQuery<IEmail, Error>(['getEmail', id], () =>
    getEmail(Number(id)),
    {
      enabled: id !== 'create',
    }
  );
};
