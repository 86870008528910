import { useEffect, useState } from 'react';

export const useClone = (useDataHook: () => any, stateArgs: Record<any, any>) => {
  const { data, isLoading } = useDataHook();
  const [dataOfForm, setDataOfForm] = useState<any>();

  useEffect(() => {
    if (stateArgs) {
      setDataOfForm(stateArgs);
      return;
    }

    setDataOfForm(data);
  }, [stateArgs, data]);

  return {
    isLoading,
    data: data || dataOfForm,
  };
};
