import * as React from 'react';
import { LoginForm } from './components/LoginForm';

export const LoginScreen = () => {
  return (
    <div className="unauthorized-view">
      <LoginForm />
    </div>
  );
};
