import * as React from 'react';
import { useActiveStats } from '../../api/hooks/stats/useStats';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { StatItemCard } from './components/StatItemCard';
import { getStatsSortByFilter } from '../../utils/data';
import { SORT_BY_STAT } from '../../interfaces/enumerables';

export const StatsTypesScreen = () => {
  const statsSortByFilter = getStatsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useActiveStats}
      ItemCardData={StatItemCard}
      sortByFilter={statsSortByFilter}
      sortByDefaultKey={SORT_BY_STAT.ID}
    />
  );
};
