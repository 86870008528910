import { combineReducers } from '@reduxjs/toolkit';
import coreReducer from './core/coreSlice';
import userReducer from './user/userSlice';

const rootReducer = combineReducers({
  core: coreReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
