import * as React from 'react';
import { useParams } from 'react-router';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { useUpdateCreateExpense } from '../../api/hooks/expenses/useUpdateCreateExpense';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { IEmail } from '../../interfaces/emails.interface';
import { useEmail } from '../../api/hooks/email/useEmail';

const getFormData = (id?: number | string, data?: IEmail): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'pubDate',
        label: 'PUB_DATE',
        placeholder: 'PUB_DATE',
        value: data?.pubDate || '',
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'dateNotification',
        label: 'NOTIFICATION_DATE',
        placeholder: 'NOTIFICATION_DATE',
        value: data?.dateNotification || '',
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'users',
        label: 'USERS',
        placeholder: 'USERS',
        value: data?.expensesEmailclientClientNotifies || '',
        type: 'text', // TODO: add multi select search
      },
    ],
  };
};

const validationSchema = yup.object({
  active: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
});

// NOT USER CURRENTLY
export const EmailScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useEmail(id);

  return (
    <div>
      <EntityForm
        data={getFormData(Number(id), data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateExpense} // TODO: Change to correct hook
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
