import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { getPlatforms } from '@ionic/react';

export enum CAPACITOR_PLATFORM {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const getLocalDate = (date: string): string => {
  if (!date) return '';
  return new Date(date).toLocaleString(['el-GR'], dateOptions);
};

export const nowDate = () => new Date().toISOString();

export const nowDateNoon = () => {
  const now = new Date();
  now.setHours(12, 0);
  return now.toISOString();
};

export const platform = Capacitor.getPlatform();

export const checkIfMobile = async (): Promise<boolean> => {
  const info = await Device.getInfo();

  return ['ios', 'android'].includes(info.operatingSystem);
};

export const isDesktop = () => {
  const platforms = getPlatforms();
  return platforms.includes('desktop');
};

export const isMobile = () => {
  const platforms = getPlatforms();
  let isMobileDevice = false;

  // Is mobile
  if (platforms.includes('mobile') || platforms.includes('mobileweb') || platforms.includes('tablet')) {
    isMobileDevice = true;
  }

  // Is tablet
  if (platforms.includes('tablet')) {
    isMobileDevice = false;
  }

  return isMobileDevice;
};

export const isTablet = () => {
  const platforms = getPlatforms();

  if (platforms.includes('tablet')) return true;

  return false;
};

export const getPaginationLayoutStyle = (specificLayoutClass?: string) => {
  if (specificLayoutClass) return specificLayoutClass;

  if (isTablet()) return 'list-max-height-tablet';

  if (isMobile()) return 'list-max-height-mobile';

  return 'list-height';
};

export const getFormLayoutStyle = () => {
  if (isMobile() || isTablet()) return 'margin-b-60';

  return '';
};

export const getISOStringWithRoundSeconds = (dateString: string): string => {
  const date = new Date(dateString);
  date.setMilliseconds(0);
  date.setSeconds(0);
  return date?.toISOString();
};

export const isObjectEmpty = (data: Record<any, any>) => Object.keys(data).length === 0;

export const keyBy = (data: any[], key: string) => {
  const objectBy = {};
  for (const val of data) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    objectBy[val?.[key]] = val;
  }

  return objectBy;
};

export const mapToArrayWithOnlyId = (initData: any[]) => {
  const arrayToUse = [];

  for (let i = 0; i < initData.length; i++) {
    arrayToUse.push({ id: initData[i]?.id });
  }

  return arrayToUse;
};
