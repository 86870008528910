import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetExpiredPayments } from '../../../interfaces/expiredPayments.interface';

const getExpiredPayments = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetExpiredPayments> => {
  const resp = await apiRQ.get<IGetExpiredPayments>('/admin/expired-payments', { params });

  return resp.data;
};

export const useExpiredPayments = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetExpiredPayments, Error>(['getExpiredPayments', page, orderBy, order, search], () =>
    getExpiredPayments({ page, orderBy, order, search }),
  );
};
