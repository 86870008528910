import * as React from 'react';
import { useEffect } from 'react';
import { IEntityForm } from './entityForm.interface';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { ErrorForm } from './ErrorForm';
import { useFormik } from 'formik';
import { AnyObject } from 'yup/lib/types';
import { formDataToSubmit, getChangedFields, getFormInput, getOnlyViewField } from '../../../utils/utils';
import { UseMutationResult } from 'react-query';
import { IGenericResponse } from '../../../interfaces/shared.interface';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GeotrianLoading } from '../loading/GeotrianLoading';
import { setToast } from '../../../store/core/coreSlice';
import { PATHS } from '../../../utils/data';
import { getFormLayoutStyle, isObjectEmpty } from '../../../utils/helpers';
import { DeleteIconButton } from '../DeleteIconButton';

interface Props {
  data: IEntityForm;
  validationSchema: AnyObject;
  submitHook: () => UseMutationResult<IGenericResponse, Error, void, unknown>;
  deleteHook?: () => UseMutationResult<IGenericResponse, Error, void, unknown>;
  deleteDataFields?: ('id' | 'id2')[];
}

export const EntityForm = ({ data, validationSchema, submitHook, deleteHook, deleteDataFields }: Props) => {
  const { t } = useTranslation();
  const layoutClass = getFormLayoutStyle();
  const initialFormData = data.fields.reduce((acc: Record<string, any>, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {});
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutate: updateCreateModel, isLoading: isLoadingMutate, isSuccess, data: resp } = submitHook();

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
      dispatch(
        setToast({
          value: { show: true, msg: resp?.error || t('SHARED.UPDATE_SUCCESSFUL'), isError: !resp?.success || false },
        }),
      );
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let dataToSubmit: Record<string, any> = values;

      // Update model
      if (data.id !== PATHS.CREATE_SUB_PATH) {
        const changedFields = getChangedFields(data.fields, initialFormData, values);

        // Nothing changed, triggered info message
        if (isObjectEmpty(changedFields)) {
          dispatch(setToast({ value: { show: true, msg: t('SHARED.NO_CHANGES'), isError: false } }));
          return;
        }

        dataToSubmit = {
          id: Number(data.id),
          ...changedFields,
        };
        // For update of client
        if (data.id2) {
          dataToSubmit.id = Number(data.id2);
          dataToSubmit.userId = Number(data.id);
        }
      }

      const finalData = formDataToSubmit(data.fields, dataToSubmit);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateCreateModel(finalData);
    },
  });

  const onKeyUp = (event: any) => {
    // Submit form on enter
    if (event.nativeEvent.key === 'Enter' && event.nativeEvent?.target?.['s-hn'] !== 'ION-TEXTAREA') {
      formik.handleSubmit();
    }
  };

  const getFields = () => {
    return data.fields.map((field) => {
      return (
        <div
          key={field.name}
          className="margin-l-8 margin-r-8 padding-b-30 flex-start-center-column-nowrap positionRelative">
          {getFormInput(field, formik)}
          <ErrorForm>{formik.errors?.[field.name] && formik.errors?.[field.name]}</ErrorForm>
        </div>
      );
    });
  };

  const getOnlyViewFields = () => {
    return data?.onlyViewFields?.map((field) => {
      if (field?.value) {
        return (
          <div
            key={field.name}
            className="margin-l-8 margin-r-8 padding-b-30 flex-start-center-column-nowrap positionRelative">
            {getOnlyViewField(field)}
          </div>
        );
      }
    });
  };

  const getDeleteButton = () => {
    if (data?.id === 'create') return null;

    if (deleteHook && deleteDataFields) {
      const deleteData: { id: number; userId?: number } = { id: Number(data[deleteDataFields[0]]) };
      if (deleteDataFields[1]) {
        deleteData.userId = Number(data[deleteDataFields[1]]);
      }

      return <DeleteIconButton submitData={deleteData} deleteHook={deleteHook} />;
    }

    return null;
  };

  return (
    <div className={layoutClass}>
      {!!data?.id && <div className="entityFormId text-shade normalText4">({data.id})</div>}
      {getDeleteButton()}
      <form onSubmit={formik.handleSubmit} onKeyUp={($event) => onKeyUp($event)}>
        <div className="padding-20">
          <div className="flex-center-center-row-wrap margin-b-24">
            {getFields()}
            {getOnlyViewFields()}
          </div>
          <div className="flex-center-center-row-wrap margin-t-16">
            <IonButton shape="round" type="submit" disabled={!formik.isValid || !formik.dirty}>
              {t('FORMS.SAVE')}
            </IonButton>
          </div>
        </div>
      </form>
      {isLoadingMutate && <GeotrianLoading />}
    </div>
  );
};
