import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IGetTypes } from '../../../interfaces/types.interface';

const getTypes = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetTypes> => {
  const resp = await apiRQ.get<IGetTypes>('/admin/expense-type', { params });

  return resp.data;
};

export const useTypes = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetTypes, Error>(['getTypes', page, orderBy, order, search], () =>
    getTypes({ page, orderBy, order, search }),
  );
};
