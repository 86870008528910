import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetPayments } from '../../../interfaces/payments.interface';

const getPayments = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetPayments> => {
  const resp = await apiRQ.get<IGetPayments>('/admin/payments', { params });

  return resp.data;
};

export const usePayments = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetPayments, Error>(['getPayments', page, orderBy, order, search], () =>
    getPayments({ page, orderBy, order, search }),
  );
};
