import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IPayment } from '../../../interfaces/payments.interface';

const getPayment = async (id: number): Promise<IPayment> => {
  const resp = await apiRQ.get<IPayment>(`/admin/payments/${id}`);

  return resp.data;
};

export const usePayment = (id: number | string) => {
  return useQuery<IPayment, Error>(['getPayment', id], () => getPayment(Number(id)), {
    enabled: id !== 'create',
  });
};
