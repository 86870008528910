import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IGetNotifications } from '../../../interfaces/notification.interface';

const getNotifications = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetNotifications> => {
  const resp = await apiRQ.get<IGetNotifications>('/admin/notifications', { params });

  return resp.data;
};

export const useNotifications = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetNotifications, Error>(['getNotifications', page, orderBy, order, search], () =>
    getNotifications({ page, orderBy, order, search }),
  );
};
