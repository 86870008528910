import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { useNotification } from '../../api/hooks/notifications/useNotification';
import { INotification } from '../../interfaces/notification.interface';
import {
  useDeleteNotification,
  useUpdateCreateNotification,
} from '../../api/hooks/notifications/useUpdateCreateNotification';
import { useClients } from '../../api/hooks/clients/useClients';
import { getLocalDate, nowDate } from '../../utils/helpers';
import { ACTION_OF_NOTIFICATION } from '../../utils/data';
import { useLocation } from 'react-router-dom';

const getFormData = (id?: number | string, data?: INotification): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'title',
        label: 'TITLE',
        placeholder: 'TITLE',
        value: data?.title || '',
        type: 'text',
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        placeholder: 'DESCRIPTION',
        value: data?.description || '',
        type: 'text',
      },
      {
        name: 'dateOfNotification',
        label: 'NOTIFICATION_DATE',
        placeholder: 'NOTIFICATION_DATE',
        value: data?.dateOfNotification || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'image',
        label: 'IMAGE_LINK',
        placeholder: 'IMAGE_LINK',
        value: data?.image || undefined,
        type: 'text',
      },
      {
        name: 'typeOfNotification',
        label: 'NOTIFICATION_TYPE',
        placeholder: 'NOTIFICATION_TYPE',
        value: Number.isInteger(Number(data?.typeOfNotification)) ? Number(data?.typeOfNotification) : 1,
        type: INPUT_TYPE.SELECT,
        fieldValue: 'value',
        data: [
          {
            label: 'NOTIFICATIONS.TO_ALL_CATEGORY',
            value: ACTION_OF_NOTIFICATION.ANNOUNCEMENT,
          },
          {
            label: 'NOTIFICATIONS.TO_UNPAID',
            value: ACTION_OF_NOTIFICATION.UNPAID_EXPENSES,
          },
          {
            label: 'NOTIFICATIONS.TO_DOCS',
            value: ACTION_OF_NOTIFICATION.DOCS,
          },
          {
            label: 'NOTIFICATIONS.TO_INFO',
            value: ACTION_OF_NOTIFICATION.INFO,
          },
          {
            label: 'NOTIFICATIONS.TO_CHARTS',
            value: ACTION_OF_NOTIFICATION.CHARTS,
          },
          {
            label: 'NOTIFICATIONS.TO_VAT',
            value: ACTION_OF_NOTIFICATION.VAT,
          },
        ],
      },
      {
        name: 'clientId',
        label: 'USER',
        placeholder: 'USER',
        value: data?.client || undefined,
        fieldToSubmit: 'id',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'firstName',
        useHook: useClients,
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  title: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_TITLE')),
  typeOfNotification: yup.number().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
});

export const NotificationScreen = () => {
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useNotification(id);
  const [dataOfForm, setDataOfForm] = useState<INotification>();

  useEffect(() => {
    if (state) {
      setDataOfForm(state as INotification);
      return;
    }

    setDataOfForm(data);
  }, [state, data]);

  return (
    <div>
      <EntityForm
        data={getFormData(id, dataOfForm)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateNotification}
        deleteHook={useDeleteNotification}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
