import {
  getLanguageStorage,
  getUserStorage,
  setLanguageStorage,
  setUserStorage
} from '../../services/localStorage.service';
import { setCurrentLanguage, setLangLoading, setLogoutLoading, setUser } from './userSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { ILanguage } from '../../config/i18n/common';
import { setCoreLoading } from '../core/coreSlice';
import { loginService, logoutService } from '../../services/auth.service';
import { LoginDTO } from '../../interfaces/auth.interface';

export const loadUserAsync = createAsyncThunk('loadUserAsync', async (_, { dispatch }) => {
  const userData = await getUserStorage();
  dispatch(setUser({ user: userData }));

  return userData;
});

export const loadLangAsync = createAsyncThunk('loadLangAsync', async (_, { dispatch }) => {
  const lang = await getLanguageStorage();
  if (lang) {
    dispatch(setLanguageAsync(lang));
  }
});

/**
 * Set current language
 */
export const setLanguageAsync = createAsyncThunk(
  'setLanguageAsync',
  async (currentLanguage: ILanguage, { dispatch }) => {
    dispatch(setLangLoading({ value: true }));
    dispatch(setCurrentLanguage({ currentLanguage }));
    await i18n.changeLanguage(currentLanguage.value);
    await setLanguageStorage(currentLanguage);
    // await dispatch(updateUserDataAsync({ lang: currentLanguage.value } as IUpdateUserData, false));
    dispatch(setLangLoading({ value: false }));
  },
);

//////////////////////////////// LOGIN ASYNC ACTIONS ////////////////////////////////

/**
 * Make call to server and if successful,
 * save userData to localStorage and update state,
 * else return error msg to screen
 * @param userData: ILogin
 */
export const loginUserAsync = createAsyncThunk('user/login', async (data: LoginDTO, { dispatch }) => {
  dispatch(setCoreLoading({ value: true }));

  // Make call to server
  const { success, user } = await loginService(data);

  // Check for success
  if (success && user) {
    dispatch(setUser({ user }));
    await setUserStorage(user);
  }

  dispatch(setCoreLoading({ value: false }));
});

/**
 * Logout user and clean data
 */
export const logoutUserAsync = createAsyncThunk('user/login', async (_, { dispatch }) => {
  dispatch(setLogoutLoading({ value: true }));
  // Delete notification token from user, so that it will no longer
  // take notifications from the app until he logs in again
  // const notificationToken = await getNotificationToken();

  const notificationToken = '';
  if (notificationToken) {
    const { success } = await logoutService(null);

    if (!success) {
      // trackEvent('Logout-User', {
      //   Error: 'Unidentified error while logging out',
      // });
    }
  }

  await setUserStorage(null);
  dispatch(setUser({ user: null }));

  dispatch(setLogoutLoading({ value: false }));
});
