import * as React from 'react';
import { Tooltip } from 'antd';

interface Props {
  width: string;
  value: string;
  classes?: string;
}

const OVERFLOW_THRESHOLDS: { [key: string]: number } = {
  'width-180px': 26,
  'width-120px': 18,
  'width-80px': 12,
};

export const ItemCardField = ({ classes = '', width = 'width-180px', value }: Props) => {
  const showTooltip = value.length > (OVERFLOW_THRESHOLDS[width] || 26);

  if (showTooltip) {
    return (
      <Tooltip title={value}>
        <div className={`${classes} ${width}`}>{value}</div>
      </Tooltip>
    );
  }

  return <div className={`${classes} ${width}`}>{value}</div>;
};
