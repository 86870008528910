import * as React from 'react';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { IStat } from '../../../interfaces/stats.interface';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';
import { PATHS } from '../../../utils/data';

interface Props {
  data: IStat;
}

export const StatItemCard = ({ data }: Props) => {
  return (
    <ItemCard
      classes={data?.active ? 'border-success' : 'border-error'}
      entityId={data.id}
      routerLink={`${PATHS.STATS}/${data.id}`}>
      <div className="flex-space-between-center-row-wrap width-100">
        <LinkTo link={`/types/${data.typeOf?.id}`}>
          <div className="margin-r-24 width-100px">{data?.typeOf?.typeOf}</div>
        </LinkTo>
      </div>
    </ItemCard>
  );
};
