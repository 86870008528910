import { IExpense } from '../../../interfaces/expenses.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';

const getExpense = async (id: number): Promise<IExpense> => {
  const resp = await apiRQ.get<IExpense>( `/admin/expenses/${id}`);

  return resp.data;
};

export const useExpense = (id: number | string) => {
  return useQuery<IExpense, Error>(['getExpense', id], () =>
    getExpense(Number(id)),
    {
      enabled: id !== 'create',
    }
  );
};
