import * as React from 'react';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { IEmail } from '../../../interfaces/emails.interface';
import { getLocalDate } from '../../../utils/helpers';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../../utils/data';

interface Props {
  data: IEmail;
}

export const EmailNewExpensesItemCard = ({ data }: Props) => {
  const { t } = useTranslation();
  const names = data.expensesEmailclientClientNotifies.map((client) => client.client?.firstName);

  return (
    <ItemCard entityId={data.id} routerLink={`${PATHS.EMAILS}/${data.id}`}>
      <div className="flex-space-between-center-row-wrap width-100">
        <ItemCardField classes="margin-r-24 width-100px" width="width-180px" value={names.join(', ')} />
        <div className="margin-r-24 width-100px">
          {t('SHARED.NOTIFICATION_MONTH', { date: getLocalDate(String(data?.dateNotification)) })}
        </div>
        <div className="width-100px">{t('SHARED.PUB_DATE', { date: getLocalDate(String(data?.pubDate)) })}</div>
      </div>
    </ItemCard>
  );
};
