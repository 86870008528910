import * as React from 'react';
import { IExpiredPayment } from '../../../interfaces/expiredPayments.interface';
import { getLocalDate } from '../../../utils/helpers';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { useTranslation } from 'react-i18next';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';

interface Props {
  data: IExpiredPayment;
}

export const ExpiredPaymentItemCard = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <ItemCard entityId={data.id} classes="unClickableCard">
      <div className="flex-space-between-center-row-wrap width-100">
        <LinkTo link={`/expenses/${data?.outgoingId}`}>
          <div className="margin-r-24 width-100px">{t('SHARED.EXPENSE_ID', { expenseId: data.outgoingId })}</div>
        </LinkTo>
        <div className="margin-r-24 width-100px">{data?.user?.username}</div>
        <div>{getLocalDate(data?.pubDate)}</div>
      </div>
    </ItemCard>
  );
};
