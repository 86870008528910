export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SORT_BY_COMPANIES {
  ID = 'id',
  TITLE = 'title',
  EMAIL = 'email',
  CREATED_AT = 'createdAt',
}

export enum SORT_BY_CLIENTS {
  ID = 'id',
  FIRST_NAME = 'firstName',
  EMAIL = 'email',
  USERNAME = 'username',
  PUB_DATE = 'pubDate',
}

export enum SORT_BY_EXPENSES {
  ID = 'id',
  CLIENT_FIRST_NAME = 'firstName',
  DEADLINE = 'deadline',
  DONE = 'done',
  DONE_DATE = 'doneDate',
  VALUE = 'value',
  TYPE = 'type',
  PUB_DATE = 'pubDate',
}

export enum SORT_BY_UPDATES {
  ID = 'id',
  CLIENT_FIRST_NAME = 'firstName',
  DONE = 'done',
  VALUE = 'value',
  TYPE = 'type',
  PUB_DATE = 'pubDate',
}

export enum SORT_BY_DOCS {
  ID = 'id',
  TITLE = 'title',
  DESCRIPTION = 'description',
  CLIENT_FIRST_NAME = 'firstName',
  PUB_DATE = 'pubDate',
}

export enum SORT_BY_TYPE_OF {
  ID = 'id',
  TYPE_OF = 'typeOf',
}

export enum SORT_BY_NOTIFICATIONS {
  ID = 'id',
  TITLE = 'title',
  DESCRIPTION = 'description',
  DATE = 'dateOfNotification',
  TYPE = 'typeOfNotification',
}

export enum SORT_BY_PAYMENTS {
  ID = 'id',
  VALUE = 'value',
  PAY_DATE = 'payDate',
  USER = 'userId',
}

export enum SORT_BY_EXPIRED_PAYMENTS {
  ID = 'id',
  PUB_DATE = 'pubDate',
  USER = 'userId',
  EXPENSE = 'outgoingId',
}

export enum SORT_BY_STAT {
  ID = 'id',
  ACTIVE = 'active',
  TYPE_OF = 'typeOf',
}

export enum SORT_BY_EMAIL {
  ID = 'id',
  PUB_DATE = 'pubDate',
  DATE_NOTIFICATION = 'dateNotification',
}

export type SORT_BY_TYPE =
  | SORT_BY_CLIENTS
  | SORT_BY_EXPENSES
  | SORT_BY_UPDATES
  | SORT_BY_DOCS
  | SORT_BY_TYPE_OF
  | SORT_BY_NOTIFICATIONS
  | SORT_BY_PAYMENTS
  | SORT_BY_EXPIRED_PAYMENTS
  | SORT_BY_STAT
  | SORT_BY_EMAIL;
