import * as React from 'react';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { ITypeOf } from '../../../interfaces/shared.interface';
import { PATHS } from '../../../utils/data';

interface Props {
  data: ITypeOf;
}

export const TypeItemCard = ({ data }: Props) => {
  return (
    <ItemCard entityId={data.id} routerLink={`${PATHS.TYPES}/${data.id}`}>
      <div className="flex-space-between-center-row-wrap width-100">
        <div className="margin-r-24">{data?.typeOf}</div>
      </div>
    </ItemCard>
  );
};
