import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { ICompany } from '../../../interfaces/companies.interface';

const getCompany = async (id: number): Promise<ICompany> => {
    const resp = await apiRQ.get<ICompany>(`/admin/companies/${id}`);

    return resp.data;
};

export const useCompany = (id: number | string) => {
    return useQuery<ICompany, Error>(['getCompany', id], () => getCompany(Number(id)), {
        enabled: id !== 'create',
    });
};
