import * as React from 'react';
import { LanguagePicker } from './components/LanguagePicker';

export const SettingsScreen = () => {
  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="page">
        <LanguagePicker />
      </div>
    </div>
  );
};
