import * as React from 'react';
import { useExpenses } from '../../api/hooks/expenses/useExpenses';
import { ExpenseItemCard } from './components/ExpenseItemCard';
import { getExpensesSortByFilter } from '../../utils/data';
import { SORT_BY_EXPENSES, SORT_DIRECTION } from '../../interfaces/enumerables';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';

export const ExpensesScreen = () => {
  const expensesSortByFilter = getExpensesSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useExpenses}
      ItemCardData={ExpenseItemCard}
      sortByFilter={expensesSortByFilter}
      sortByDefaultKey={SORT_BY_EXPENSES.PUB_DATE}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
