import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetClients } from '../../../interfaces/clients.interface';

const getClients = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
  staticParam?: string | boolean | number;
}): Promise<IGetClients> => {
  const resp = await apiRQ.get<IGetClients>('/admin/clients', { params });

  return resp.data;
};

export const useClients = (
  page: number,
  sortrBy = 'firstName',
  order = 'ASC',
  search: string,
  staticParam: { [key: string]: any } = {},
) => {
  return useQuery<IGetClients, Error>(['getClients', page, sortrBy, order, search, staticParam], () =>
    getClients({ page, orderBy: sortrBy, order, search: search, ...staticParam }),
  );
};
