import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IUpdate } from '../../../interfaces/updates.interface';

const getUpdate = async (id: number): Promise<IUpdate> => {
  const resp = await apiRQ.get<IUpdate>(`/admin/updates/${id}`);

  return resp.data;
};

export const useUpdate = (id: number | string) => {
  return useQuery<IUpdate, Error>(['getUpdate', id], () => getUpdate(Number(id)), {
    enabled: id !== 'create',
  });
};
