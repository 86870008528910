import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { ITypeOf } from '../../../interfaces/shared.interface';

const getType = async (id: number): Promise<ITypeOf> => {
  const resp = await apiRQ.get<ITypeOf>(`/admin/expense-type/${id}`);

  return resp.data;
};

export const useType = (id: number | string) => {
  return useQuery<ITypeOf, Error>(['getType', id], () =>
    getType(Number(id)),
    {
      enabled: id !== 'create',
    }
  );
};