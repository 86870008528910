import * as React from 'react';
import { useParams } from 'react-router';
import { IEntityForm } from '../../components/shared/forms/entityForm.interface';
import { ITypeOf } from '../../interfaces/shared.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { useType } from '../../api/hooks/types/useType';
import { useDeleteType, useUpdateCreateType } from '../../api/hooks/types/useUpdateCreateType';
import { getLocalDate } from '../../utils/helpers';

const getFormData = (id?: number | string, data?: ITypeOf): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'typeOf',
        label: 'TYPE_NAME',
        placeholder: 'TYPE_NAME',
        value: data?.typeOf || '',
        type: 'text',
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  typeOf: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_TYPE_NAME')),
});

export const TypeScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useType(id);

  return (
    <div>
      <EntityForm
        data={getFormData(id, data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateType}
        deleteHook={useDeleteType}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
