import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetExpenses } from '../../../interfaces/expenses.interface';

const getExpenses = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
  staticParam?: string | boolean | number;
}): Promise<IGetExpenses> => {
  const resp = await apiRQ.get<IGetExpenses>('/admin/expenses', { params });

  return resp.data;
};

export const useExpenses = (
  page: number,
  orderBy = 'id',
  order = 'ASC',
  search: string,
  staticParam: { [key: string]: any } = {},
) => {
  return useQuery<IGetExpenses, Error>(['getExpenses', page, orderBy, order, search, staticParam], () =>
    getExpenses({ page, orderBy, order, search, ...staticParam }),
  );
};
