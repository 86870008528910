import * as React from 'react';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { usePayments } from '../../api/hooks/payments/usePayments';
import { PaymentItemCard } from './components/PaymentItemCard';
import { getPaymentsSortByFilter } from '../../utils/data';
import { SORT_BY_PAYMENTS, SORT_DIRECTION } from '../../interfaces/enumerables';

export const PaymentsScreen = () => {
  const paymentsSortByFilter = getPaymentsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={usePayments}
      ItemCardData={PaymentItemCard}
      sortByFilter={paymentsSortByFilter}
      sortByDefaultKey={SORT_BY_PAYMENTS.ID}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
