import * as React from 'react';
import { IonIcon } from '@ionic/react';
import { duplicateOutline } from 'ionicons/icons';
import { LinkTo } from './ItemCard/LinkTo';

interface Props {
  data: Record<any, any>;
  path: string;
}

export const CloneEntityButton = ({ data, path }: Props) => {
  return (
    <div className="flex-end-center-row-nowrap" style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: -18, right: -10 }}>
        <LinkTo link={`${path}/create`} data={data}>
          <IonIcon icon={duplicateOutline} style={{ fontSize: 18 }}></IonIcon>
        </LinkTo>
      </div>
    </div>
  );
};
