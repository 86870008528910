import { IonApp, setupIonicReact } from '@ionic/react';
import React from 'react';
import './config/i18n/setupI18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import 'antd/dist/antd.min.css';
import './theme/index.scss';
import { RootNavigator } from './navigation/RootNavigator';
import APIProvider from './api/APIProvider';
import { Provider } from './store/store';
import { LoadingOverlay } from './components/shared/loading/LoadingOverlay';

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <APIProvider>
        <Provider>
          <LoadingOverlay />
          <RootNavigator />
        </Provider>
      </APIProvider>
    </IonApp>
  );
};

export default App;
