import axios, { AxiosRequestConfig } from 'axios';
import { Network } from '@capacitor/network';
import { getUserStorage } from '../services/localStorage.service';

export const onFulfilled = async (config: AxiosRequestConfig): Promise<any> => {
    const deviceData = {};
    const userData = await getUserStorage();
    // const deviceData = {
    //     appVersion: appVersionString,
    //     platform: `${Platform.OS} ${Platform.Version}`,
    //     userId: userData?.user?.id,
    //     userType: 'customer',
    // };

    if (config.method !== 'OPTIONS' && config.headers) {
        if (userData) {
            // If token is found add it to the header
            config.headers['authorization'] = 'AJWT ' + (userData?.jwt || '');
        }
        config.headers.logInfo = JSON.stringify(deviceData);
    }
    return config;
};

export const netCheck = async (config: AxiosRequestConfig): Promise<any> => {
    const state = await Network.getStatus();
    if (!state?.connected) {
        throw new axios.Cancel('ERRORS.NO_INTERNET');
    }
    return config;
};

export const onRejected = (error: Error) => {
    return Promise.reject(error);
};