import * as React from 'react';
import { useParams } from 'react-router';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { IStat } from '../../interfaces/stats.interface';
import { useStat } from '../../api/hooks/stats/useStat';
import { useDeleteStat, useUpdateCreateStat } from '../../api/hooks/stats/useUpdateCreateStat';
import { useTypes } from '../../api/hooks/types/useTypes';
import { getLocalDate } from '../../utils/helpers';

const getFormData = (id?: number | string, data?: IStat): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'active',
        label: 'ACTIVE',
        placeholder: 'ACTIVE',
        value: !!data?.active,
        type: INPUT_TYPE.TOGGLE,
      },
      {
        name: 'typeOfId',
        label: 'TYPE',
        placeholder: 'TYPE',
        value: data?.typeOf || '',
        fieldToSubmit: 'id',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'typeOf',
        useHook: useTypes,
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  active: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  typeOfId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).nullable(),
});

export const StatsTypeScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useStat(id);

  return (
    <div>
      <EntityForm
        data={getFormData(id, data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateStat}
        deleteHook={useDeleteStat}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
