import { IGenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';

const updateCreatePayment = async (variables: any): Promise<IGenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<IGenericResponse>('/admin/payments/', { data: variables });

  return resp.data;
};

export const useUpdateCreatePayment = () => {
  return useMutation<IGenericResponse, Error>(updateCreatePayment);
};

const deletePayment = async (data: any): Promise<IGenericResponse> => {
  const resp = await apiRQ.delete<IGenericResponse>('/admin/payments/', { data });

  return resp.data;
};

export const useDeletePayment = () => {
  return useMutation<IGenericResponse, Error>(deletePayment);
};
