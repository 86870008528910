import * as React from 'react';
import { getDocsSortByFilter } from '../../utils/data';
import { SORT_BY_DOCS, SORT_DIRECTION } from '../../interfaces/enumerables';
import { DocItemCard } from './components/DocItemCard';
import { useDocs } from '../../api/hooks/docs/useDocs';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';

export const DocumentsScreen = () => {
  const docsSortByFilter = getDocsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useDocs}
      ItemCardData={DocItemCard}
      sortByFilter={docsSortByFilter}
      sortByDefaultKey={SORT_BY_DOCS.PUB_DATE}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
