import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { TypePill } from '../../../components/shared/ItemCard/TypePill';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { getLocalDate } from '../../../utils/helpers';
import * as React from 'react';
import { useCallback } from 'react';
import { IUpdate } from '../../../interfaces/updates.interface';
import { LinkTo } from '../../../components/shared/ItemCard/LinkTo';
import { PATHS } from '../../../utils/data';

interface Props {
  data: IUpdate;
}

export const UpdateItemCard = ({ data }: Props) => {
  const getOwner = useCallback(() => {
    if (data.company) {
      return (
        <LinkTo link={`/companies/${data.company?.id}`}>
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.company?.title || ''} />
        </LinkTo>
      );
    }

    return (
      <LinkTo link={`/clients/${data.client?.id}`}>
        <ItemCardField
          classes="margin-r-24"
          width="width-180px"
          value={data?.client?.firstName || data?.client?.lastName || ''}
        />
      </LinkTo>
    );
  }, [data.company, data.client]);

  return (
    <ItemCard
      classes={data?.done ? 'border-success' : 'border-error'}
      entityId={data.id}
      routerLink={`${PATHS.UPDATES}/${data.id}`}>
      <>
        <TypePill type={data?.typeOf?.typeOf} />
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.title} />
          {getOwner()}
          <div className="margin-r-24 width-80px">{data?.value && `${data?.value} €`}</div>
          <div className="margin-r-24 width-180px">{getLocalDate(data?.pubDate)}</div>
        </div>
      </>
    </ItemCard>
  );
};
