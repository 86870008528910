import * as React from 'react';
import { useParams } from 'react-router';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import * as yup from 'yup';
import i18next from 'i18next';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { IDocument } from '../../interfaces/documents.inteface';
import { useDoc } from '../../api/hooks/docs/useDoc';
import { useDeleteDoc, useUpdateCreateDoc } from '../../api/hooks/docs/useUpdateCreateDoc';
import { useClients } from '../../api/hooks/clients/useClients';
import { getLocalDate, nowDate } from '../../utils/helpers';
import { useCompanies } from '../../api/hooks/companies/useCompanies';

const getFormData = (id?: number | string, data?: IDocument): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'title',
        label: 'TITLE',
        placeholder: 'TITLE',
        value: data?.title || '',
        type: 'text',
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        placeholder: 'DESCRIPTION',
        value: data?.description || '',
        type: 'text',
      },
      {
        name: 'file',
        label: 'FILE',
        placeholder: 'FILE',
        value: data?.file || '',
        type: 'text',
      },
      {
        name: 'pubDate',
        label: 'PUB_DATE',
        placeholder: 'PUB_DATE',
        value: data?.pubDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'clientId',
        label: 'USER',
        placeholder: 'USER',
        value: data?.client || null,
        fieldToSubmit: 'id',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'firstName',
        useHook: useClients,
      },
      {
        name: 'companyId',
        label: 'COMPANY',
        placeholder: 'COMPANY',
        value: data?.company || null,
        fieldToSubmit: 'id',
        type: INPUT_TYPE.SELECT_SEARCH,
        fieldValue: 'title',
        useHook: useCompanies,
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object().shape(
  {
    title: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_TITLE')),
    file: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_TITLE')).url(i18next.t('FORMS.ERRORS.INVALID_TYPE')),
    clientId: yup
      .object()
      .when('companyId', {
        is: (companyId: any) => !companyId,
        then: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).nullable(),
      })
      .nullable(),
    companyId: yup
      .object()
      .when('clientId', {
        is: (clientId: any) => !clientId,
        then: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).nullable(),
      })
      .nullable(),
  },
  [['clientId', 'companyId']],
);

export const DocumentScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useDoc(id);

  return (
    <div>
      <EntityForm
        data={getFormData(id, data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateDoc}
        deleteHook={useDeleteDoc}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
