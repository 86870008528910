import * as React from 'react';
import { NavigationCard } from './components/NavigationCard';
import { navigationData } from '../../utils/navigationData';
import { useTranslation } from 'react-i18next';
import { useClients } from '../../api/hooks/clients/useClients';
import { ClientItemCard } from '../Clients/components/ClientItemCard';
import { SORT_BY_CLIENTS } from '../../interfaces/enumerables';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { getClientsSortByFilter } from '../../utils/data';

export const HomeScreen = () => {
  const { t } = useTranslation();
  const clientsSortByFilter = getClientsSortByFilter();

  // const dispatch = useAppDispatch();
  //
  // const testLoader = () => {
  //   dispatch(setCoreLoading({ value: true }));
  //   setTimeout(() => {
  //     dispatch(setCoreLoading({ value: false }));
  //   }, 4000);
  // };

  const getNavigationCards = () => {
    return navigationData.map((item) => {
      return <NavigationCard key={item.title} title={item.title} navigateTo={item.url} />;
    });
  };

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="page flex-center-center-column-nowrap">
        <div className="margin-b-16 mediumText5">{t('HOME.SELECT_MODEL')}</div>
        {/*<IonButton onClick={testLoader} className="margin-b-32">Loader for 4 second</IonButton>*/}
        <div className="width-100 flex-center-center-row-wrap flex-gap-10">{getNavigationCards()}</div>
      </div>
      <div className="page flex-center-center-column-nowrap">
        <div className="margin-b-4 mediumText5">{t('HOME.CLIENTS_UNPAID_EXPENSES')}</div>
        <EntityPaginationData
          useDataHook={useClients}
          ItemCardData={ClientItemCard}
          sortByFilter={clientsSortByFilter}
          sortByDefaultKey={SORT_BY_CLIENTS.ID}
          staticParam={{ unpaidExpenses: true }}
          hideCreateButton
          specificLayoutClass="list-height-home"
        />
        <div className="margin-b-32"></div>
      </div>
    </div>
  );
};
