import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IStat } from '../../../interfaces/stats.interface';

const getStat = async (id: number): Promise<IStat> => {
  const resp = await apiRQ.get<IStat>(`/admin/stats/${id}`);

  return resp.data;
};

export const useStat = (id: number | string) => {
  return useQuery<IStat, Error>(['getStat', id], () =>
    getStat(Number(id)),
    {
      enabled: id !== 'create',
    }
  );
};
