import * as React from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { IUserClient } from '../../interfaces/auth.interface';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { useClient } from '../../api/hooks/clients/useClient';
import * as yup from 'yup';
import i18next from 'i18next';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { useDeleteClient, useUpdateCreateClient } from '../../api/hooks/clients/useUpdateCreateClient';
import { getExpensesSortByFilter, PATHS } from '../../utils/data';
import { useAppSelector } from '../../store/hooks';
import { selectIsSuperuser } from '../../store/user/userSlice';
import { getLocalDate, mapToArrayWithOnlyId } from '../../utils/helpers';
import { NotificationEligibility } from './components/NotificationEligibility';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { SORT_BY_EXPENSES, SORT_DIRECTION } from '../../interfaces/enumerables';
import { useExpenses } from '../../api/hooks/expenses/useExpenses';
import { ExpenseItemCard } from '../Expenses/components/ExpenseItemCard';
import { useTranslation } from 'react-i18next';
import { useCompanies } from '../../api/hooks/companies/useCompanies';

const DEFAULT_DUMMY_CODE = '********';

const getFormData = (id?: number | string, data?: IUserClient, disableSuperUser?: boolean): IEntityForm => {
  return {
    id,
    id2: data?.userId,
    fields: [
      {
        name: 'username',
        label: 'USERNAME',
        placeholder: 'USERNAME',
        value: data?.user?.username || '',
        type: 'text',
      },
      {
        name: 'password',
        label: 'PASSWORD',
        placeholder: 'PASSWORD',
        value: id === PATHS.CREATE_SUB_PATH ? '' : DEFAULT_DUMMY_CODE,
        type: 'password',
      },
      {
        name: 'firstName',
        label: 'FIRST_NAME',
        placeholder: 'FIRST_NAME',
        value: data?.firstName || '',
        type: 'text',
      },
      {
        name: 'lastName',
        label: 'LAST_NAME',
        placeholder: 'LAST_NAME',
        value: data?.lastName || '',
        type: 'text',
      },
      {
        name: 'email',
        label: 'EMAIL',
        placeholder: 'EMAIL',
        value: data?.email || '',
        type: 'email',
      },
      {
        name: 'phoneNumber',
        label: 'PHONE_NUMBER',
        placeholder: 'PHONE_NUMBER',
        value: data?.phoneNumber || '',
        type: 'tel',
      },
      {
        name: 'vatNumber',
        label: 'VAT_NUMBER',
        placeholder: 'VAT_NUMBER',
        value: data?.vatNumber || '',
        type: 'text',
      },
      {
        name: 'companies',
        label: 'COMPANIES',
        placeholder: 'COMPANIES',
        value: data?.companies || [],
        type: INPUT_TYPE.MULTI_SELECT_SEARCH,
        fieldValue: 'title',
        fieldMap: (dataToUse) => mapToArrayWithOnlyId(dataToUse),
        useHook: useCompanies,
      },
      {
        name: 'isActive',
        label: 'ACTIVE_USER',
        placeholder: 'ACTIVE_USER',
        value: !!data?.user?.isActive,
        type: INPUT_TYPE.TOGGLE,
      },
      {
        name: 'isStaff',
        label: 'STAFF',
        placeholder: 'STAFF',
        value: !!data?.user?.isStaff,
        type: INPUT_TYPE.TOGGLE,
      },
      {
        name: 'isSuperuser',
        label: 'SUPERUSER',
        placeholder: 'SUPERUSER',
        value: !!data?.user?.isSuperuser,
        type: INPUT_TYPE.TOGGLE,
        disabled: disableSuperUser,
      },
    ],
    onlyViewFields: [
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  username: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_USERNAME')),
  password: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_PASSWORD')),
  firstName: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIRST_NAME')),
  lastName: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_LAST_NAME')),
  email: yup
    .string()
    .required(i18next.t('FORMS.ERRORS.REQUIRED_EMAIL'))
    .email(i18next.t('FORMS.ERRORS.VALID_REQUIRED_EMAIL')),
  phoneNumber: yup.string().optional(),
  vatNumber: yup
    .number()
    .typeError(i18next.t('FORMS.ERRORS.VALID_REQUIRED_VAT_NUMBER')),
  companies: yup.object().optional().nullable(),
});

export const ClientScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isLoading } = useClient(id);
  const isSuperuser = useAppSelector(selectIsSuperuser);
  const expensesSortByFilter = getExpensesSortByFilter();

  const getExpensesOfClient = useCallback(() => {
    if (id !== 'create') {
      return (
        <div className="flex-center-center-column-nowrap margin-t-10 width-100">
          <EntityPaginationData
            useDataHook={useExpenses}
            ItemCardData={ExpenseItemCard}
            sortByFilter={expensesSortByFilter}
            sortDirectionValue={SORT_DIRECTION.DESC}
            sortByDefaultKey={SORT_BY_EXPENSES.DEADLINE}
            hideCreateButton
            hideSearchButton
            specificLayoutClass="list-client-screen"
            staticParam={{ clientId: id }}
          />
        </div>
      );
    }
  }, [id]);

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <NotificationEligibility numberOfTokens={data?.notifications?.length || 0} alignCenter />
      <EntityForm
        data={getFormData(id, data, !isSuperuser)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateClient}
        deleteHook={useDeleteClient}
        deleteDataFields={['id2', 'id']}
      />
      {isLoading && <GeotrianLoading />}
      {getExpensesOfClient()}
    </div>
  );
};
