import * as React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { logoutUserAsync } from '../../store/user/userActions';
import { useAppDispatch } from '../../store/hooks';
import { logOutOutline } from 'ionicons/icons';

export const LogoutButton = () => {
  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logoutUserAsync());
  };

  return (
    <IonButton onClick={logout}>
      <IonIcon slot="icon-only" icon={logOutOutline} color="danger" />
    </IonButton>
  );
};
