import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import * as React from 'react';
import { INotification } from '../../../interfaces/notification.interface';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { getLocalDate } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { PATHS, TRANSLATION_NOT_MAP } from '../../../utils/data';
import { CloneEntityButton } from '../../../components/shared/CloneEntityButton';

interface Props {
  data: INotification;
}

export const NotificationItemCard = ({ data }: Props) => {
  const { t } = useTranslation();

  const getTypeOfNotification = () => {
    return t(`NOTIFICATIONS.${TRANSLATION_NOT_MAP?.[data?.typeOfNotification]?.translationKey}`);
  };

  return (
    <ItemCard entityId={data.id} routerLink={`${PATHS.NOTIFICATIONS}/${data.id}`}>
      <>
        <CloneEntityButton data={data} path={PATHS.NOTIFICATIONS} />
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.title} />
          <ItemCardField classes="margin-r-24" width="width-180px" value={data?.description} />
          <div className="text-success">{getLocalDate(data?.dateOfNotification)}</div>
          <ItemCardField width="width-120px" value={getTypeOfNotification()} />
        </div>
      </>
    </ItemCard>
  );
};
