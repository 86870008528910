import { ICoreSlice, ICoreState, ICoreToast } from './coreSlice.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetBooleanPayload, SetCoreToastPayload } from '../../interfaces/store.interface';

const initialState: ICoreState = {
  loading: false,
  toast: { show: false, msg: '', isError: true },
  alertIsOpen: false,
  btnLoading: false,
};

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setCoreLoading: (state: ICoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.loading = action.payload.value;
    },
    setToast: (state: ICoreState, action: PayloadAction<SetCoreToastPayload>): void => {
      state.toast = action.payload.value;
    },
    setAlertIsOpen: (state: ICoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.alertIsOpen = action.payload.value;
    },
    setBtnLoading: (state: ICoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.btnLoading = action.payload.value;
    },
    resetError: (state: ICoreState): void => {
      state.toast = initialState.toast;
    },
    resetCore: (): ICoreState => initialState,
  },
});

//////////////////////////////// USER ASYNC ACTIONS ////////////////////////////////

//////////////////////////////// ACTIONS ////////////////////////////////
export const { setAlertIsOpen, setCoreLoading, setToast, setBtnLoading, resetCore, resetError } = coreSlice.actions;

//////////////////////////////// SELECTORS ////////////////////////////////
export const selectCoreLoading = (state: ICoreSlice): boolean => state.core.loading;
export const selectCoreToast = (state: ICoreSlice): ICoreToast => state.core.toast;
export const selectBtnLoading = (state: ICoreSlice): boolean => state.core.btnLoading;

export default coreSlice.reducer;
