import * as React from 'react';
import { useCallback } from 'react';
import * as yup from 'yup';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { EntityForm } from '../../components/shared/forms/EntityForm';
import { GeotrianLoading } from '../../components/shared/loading/GeotrianLoading';
import { useCompany } from '../../api/hooks/companies/useCompany';
import { IEntityForm, INPUT_TYPE } from '../../components/shared/forms/entityForm.interface';
import { getLocalDate, mapToArrayWithOnlyId } from '../../utils/helpers';
import { ICompany } from '../../interfaces/companies.interface';
import { useDeleteCompany, useUpdateCreateCompany } from '../../api/hooks/companies/useUpdateCreateCompany';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { useExpenses } from '../../api/hooks/expenses/useExpenses';
import { ExpenseItemCard } from '../Expenses/components/ExpenseItemCard';
import { SORT_BY_EXPENSES, SORT_DIRECTION } from '../../interfaces/enumerables';
import { getExpensesSortByFilter } from '../../utils/data';
import { useClients } from '../../api/hooks/clients/useClients';

const getFormData = (id?: number | string, data?: ICompany): IEntityForm => {
  return {
    id,
    fields: [
      {
        name: 'title',
        label: 'TITLE',
        placeholder: 'TITLE',
        value: data?.title || '',
        type: 'text',
      },
      {
        name: 'vatNumber',
        label: 'VAT_NUMBER',
        placeholder: 'VAT_NUMBER',
        value: data?.vatNumber || '',
        type: 'text',
      },
      {
        name: 'email',
        label: 'EMAIL',
        placeholder: 'EMAIL',
        value: data?.email || '',
        type: 'text',
      },
      {
        name: 'phoneNumber',
        label: 'PHONE_NUMBER',
        placeholder: 'PHONE_NUMBER',
        value: data?.phoneNumber || '',
        type: 'text',
      },
      {
        name: 'aadeUsername',
        label: 'AADE_USERNAME',
        placeholder: 'AADE_USERNAME',
        value: data?.aadeUsername || '',
        type: 'text',
      },
      {
        name: 'aadePassword',
        label: 'AADE_PASSWORD',
        placeholder: 'AADE_PASSWORD',
        value: data?.aadePassword || '',
        type: 'text',
      },
      {
        name: 'taxisUsername',
        label: 'TAXIS_USERNAME',
        placeholder: 'TAXIS_USERNAME',
        value: data?.taxisUsername || '',
        type: 'text',
      },
      {
        name: 'taxisPassword',
        label: 'TAXIS_PASSWORD',
        placeholder: 'TAXIS_PASSWORD',
        value: data?.taxisPassword || '',
        type: 'text',
      },
      {
        name: 'users',
        label: 'USERS',
        placeholder: 'USERS',
        value: data?.users || [],
        type: INPUT_TYPE.MULTI_SELECT_SEARCH,
        fieldValue: 'firstName',
        fieldValue2: 'lastName',
        fieldMap: (dataToUse) => mapToArrayWithOnlyId(dataToUse),
        useHook: useClients,
      },
    ],
    onlyViewFields: [
      {
        name: 'createdAt',
        label: 'CREATED_AT',
        placeholder: 'CREATED_AT',
        value: data?.createdAt ? getLocalDate(String(data?.createdAt)) : '',
        type: 'text',
        disabled: true,
      },
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDate(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  title: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_TITLE')),
  vatNumber: yup
    .number()
    .required(i18next.t('FORMS.ERRORS.REQUIRED_VAT_NUMBER'))
    .typeError(i18next.t('FORMS.ERRORS.VALID_REQUIRED_VAT_NUMBER')),
  email: yup.string().optional().email(i18next.t('FORMS.ERRORS.VALID_REQUIRED_EMAIL')),
  phoneNumber: yup.string().optional(),
  aadeUsername: yup.string().optional(),
  aadePassword: yup.string().optional(),
  taxisUsername: yup.string().optional(),
  taxisPassword: yup.string().optional(),
  clients: yup.object().optional().nullable(),
});

export const CompanyScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useCompany(id);
  const expensesSortByFilter = getExpensesSortByFilter();

  const getExpensesOfClient = useCallback(() => {
    if (id !== 'create') {
      return (
        <div className="flex-center-center-column-nowrap margin-t-10 width-100">
          <EntityPaginationData
            useDataHook={useExpenses}
            ItemCardData={ExpenseItemCard}
            sortByFilter={expensesSortByFilter}
            sortDirectionValue={SORT_DIRECTION.DESC}
            sortByDefaultKey={SORT_BY_EXPENSES.DEADLINE}
            hideCreateButton
            hideSearchButton
            specificLayoutClass="list-client-screen"
            staticParam={{ companyId: id }}
          />
        </div>
      );
    }
  }, [id]);

  return (
    <div>
      <EntityForm
        data={getFormData(id, data)}
        validationSchema={validationSchema}
        submitHook={useUpdateCreateCompany}
        deleteHook={useDeleteCompany}
        deleteDataFields={['id']}
      />
      {isLoading && <GeotrianLoading />}
      {getExpensesOfClient()}
    </div>
  );
};
