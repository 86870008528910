import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { INotification } from '../../../interfaces/notification.interface';

const getNotification = async (id: number): Promise<INotification> => {
  const resp = await apiRQ.get<INotification>(`/admin/notifications/${id}`);

  return resp.data;
};

export const useNotification = (id: number | string) => {
  return useQuery<INotification, Error>(['getNotification', id], () =>
    getNotification(Number(id)),
    {
      enabled: id !== 'create',
    }
  );
};
