import { IGenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';

const updateCreateDoc = async (variables: any): Promise<IGenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<IGenericResponse>('/admin/documents/', { data: variables });

  return resp.data;
};

export const useUpdateCreateDoc = () => {
  return useMutation<IGenericResponse, Error>(updateCreateDoc);
};

const deleteDoc = async (data: any): Promise<IGenericResponse> => {
  const resp = await apiRQ.delete<IGenericResponse>('/admin/documents/', { data });

  return resp.data;
};

export const useDeleteDoc = () => {
  return useMutation<IGenericResponse, Error>(deleteDoc);
};
