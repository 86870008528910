import * as React from 'react';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { PATHS } from '../../../utils/data';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { ICompany } from '../../../interfaces/companies.interface';

interface Props {
  data: ICompany;
}

export const CompanyItemCard = ({ data }: Props) => {
  return (
    <ItemCard routerLink={`${PATHS.COMPANIES}/${data.id}`} entityId={data.id}>
      <div className="flex-space-between-center-row-wrap width-100">
        <ItemCardField classes="margin-r-24" width="width-180px" value={data?.title} />
        <div className="margin-r-24 width-120px">{data?.vatNumber}</div>
        <ItemCardField width="width-180px" value={data?.email || ''} />
      </div>
    </ItemCard>
  );
};
