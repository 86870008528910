import * as React from 'react';
import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';

export const MyBackButton = () => {
  const router = useIonRouter();
  const location = useLocation();

  const goBack = () => {
    router.goBack();
  };

  if (router.canGoBack() && location?.pathname !== '/') {
    return (
      <IonButton onClick={goBack}>
        <IonIcon slot="icon-only" icon={arrowBack} />
      </IonButton>
    );
  }

  return null;
};
