import { PATHS } from './data';
import {
  businessOutline,
  businessSharp,
  cashOutline,
  cashSharp,
  documentsOutline,
  documentsSharp,
  folderOutline,
  folderSharp,
  homeOutline,
  homeSharp,
  newspaperOutline,
  newspaperSharp,
  notificationsCircleOutline,
  notificationsCircleSharp,
  notificationsOutline,
  notificationsSharp,
  peopleOutline,
  peopleSharp,
  recordingOutline,
  recordingSharp,
  settingsOutline,
  settingsSharp,
  statsChartOutline,
  statsChartSharp,
} from 'ionicons/icons';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

export const navigationData = [
  {
    title: 'TABS.USERS',
    url: PATHS.CLIENTS,
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: 'TABS.COMPANIES',
    url: PATHS.COMPANIES,
    iosIcon: businessOutline,
    mdIcon: businessSharp,
  },
  {
    title: 'TABS.EXPENSES',
    url: PATHS.EXPENSES,
    iosIcon: folderOutline,
    mdIcon: folderSharp,
  },
  {
    title: 'TABS.UPDATES',
    url: PATHS.UPDATES,
    iosIcon: newspaperOutline,
    mdIcon: newspaperSharp,
  },
  {
    title: 'TABS.DOCUMENTS',
    url: PATHS.DOCUMENTS,
    iosIcon: documentsOutline,
    mdIcon: documentsSharp,
  },
  {
    title: 'TABS.TYPES',
    url: PATHS.TYPES,
    iosIcon: recordingOutline,
    mdIcon: recordingSharp,
  },
  {
    title: 'TABS.NOTIFICATIONS',
    url: PATHS.NOTIFICATIONS,
    iosIcon: notificationsOutline,
    mdIcon: notificationsSharp,
  },
  {
    title: 'TABS.PAYMENTS',
    url: PATHS.PAYMENTS,
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: 'TABS.EXPIRED_EXPENSE_NOTIFICATIONS',
    url: PATHS.EXPIRED_EXPENSES,
    iosIcon: notificationsCircleOutline,
    mdIcon: notificationsCircleSharp,
  },
  {
    title: 'TABS.ACTIVE_STATS',
    url: PATHS.STATS,
    iosIcon: statsChartOutline,
    mdIcon: statsChartSharp,
  },
  // {
  //   title: 'TABS.EMAILS',
  //   url: '/emails',
  //   iosIcon: mailOutline,
  //   mdIcon: mailSharp,
  // },
  {
    title: 'TABS.SETTINGS',
    url: PATHS.SETTINGS,
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
  },
];

export const appPagesMenu: AppPage[] = [
  {
    title: 'TABS.HOME',
    url: PATHS.HOME,
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  ...navigationData,
];
