import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IonImg } from '@ionic/react';

interface Props {
  imgSource: any;
  msg: string;
}

export const DataPlaceholder = ({ imgSource, msg }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex-center-center-column-nowrap margin-t-80">
      <IonImg src={imgSource} style={{ width: '320px', height: '240' }} />
      <div className="mediumText5 margin-t-40 text-center">{t(msg)}</div>
    </div>
  );
};
