import en from './en.json';
import el from './el.json';
import enUs from 'antd/lib/locale/en_US';
import elGr from 'antd/lib/locale/el_GR';

export const translations = {
  en: {
    translation: en,
  },
  el: {
    translation: el,
  },
};

export enum LANGUAGES {
  EN = 'en',
  EL = 'el',
}

export interface ILanguage {
  label: string;
  value: LANGUAGES;
  antd: any;
  locale: string;
}

export const availableLanguages: ILanguage[] = [
  { label: 'Ελληνικά', value: LANGUAGES.EL, antd: elGr, locale: 'el-GR' },
  { label: 'English', value: LANGUAGES.EN, antd: enUs, locale: 'en-US' },
];

export const availableLanguagesObj: Record<LANGUAGES, ILanguage> = {
  [LANGUAGES.EL]: availableLanguages[0],
  [LANGUAGES.EN]: availableLanguages[1],
};
