import * as React from 'react';
import { IonPage, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { LoginScreen } from '../screens/Login/LoginScreen';
import { selectShowMainNav } from '../store/user/userSlice';
import { useAppSelector } from '../store/hooks';
import Menu from '../components/Menu';
import { NavigationContainer } from './NavigationContainer';

export const MainNavigator = () => {
  const showMainNav = useAppSelector(selectShowMainNav);

  const mainNav = (
    <IonReactRouter>
      <IonSplitPane contentId="main">
        <Menu />
        <IonPage id="main">
          <IonRouterOutlet>
            <NavigationContainer />
          </IonRouterOutlet>
        </IonPage>
      </IonSplitPane>
    </IonReactRouter>
  );

  return showMainNav ? mainNav : <LoginScreen />;
};
