import * as React from 'react';
import { useEffect } from 'react';
import { useIonToast } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetError, selectCoreLoading, selectCoreToast } from '../../../store/core/coreSlice';
import '../shared.scss';
import { GeotrianLoading } from './GeotrianLoading';

export const LoadingOverlay = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectCoreLoading);
  const coreToast = useAppSelector(selectCoreToast);
  const [present] = useIonToast();

  useEffect(() => {
    if (coreToast?.show) {
      present({
        message: coreToast.msg,
        mode: 'ios',
        duration: coreToast.isError ? 4000 : 2000,
        position: 'bottom',
        cssClass: coreToast.isError ? 'text-danger' : '',
        onDidDismiss: () => dispatch(resetError()),
        color: 'light',
        buttons: [
          {
            text: 'X',
            role: 'cancel',
          },
        ],
      });
    }
  }, [coreToast]);

  if (loading) {
    return <GeotrianLoading />;
  }
  return null;
};
