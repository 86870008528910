import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IUserClient } from '../../../interfaces/auth.interface';

const getClient = async (id: number): Promise<IUserClient> => {
  const resp = await apiRQ.get<IUserClient>(`/admin/clients/${id}`);

  return resp.data;
};

export const useClient = (id: number | string) => {
  return useQuery<IUserClient, Error>(['getClient', id], () => getClient(Number(id)), {
    enabled: id !== 'create',
  });
};
