import { IGenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';

const updateCreateStat = async (variables: any): Promise<IGenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<IGenericResponse>('/admin/stats/', { data: variables });

  return resp.data;
};

export const useUpdateCreateStat = () => {
  return useMutation<IGenericResponse, Error>(updateCreateStat);
};

const deleteStat = async (data: any): Promise<IGenericResponse> => {
  const resp = await apiRQ.delete<IGenericResponse>('/admin/stats/', { data });

  return resp.data;
};

export const useDeleteStat = () => {
  return useMutation<IGenericResponse, Error>(deleteStat);
};
