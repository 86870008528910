import { Preferences } from '@capacitor/preferences';
import { ILoggedInUser } from '../interfaces/auth.interface';
import { ILanguage } from '../config/i18n/common';

export const setUserStorage = async (userData: ILoggedInUser | null): Promise<void> => {
  try {
    await Preferences.set({ key: 'user', value: JSON.stringify(userData) });
  } catch (e) {
    console.error('Error while saving user data');
  }
};

export const getUserStorage = async (): Promise<ILoggedInUser | null> => {
  try {
    const res = await Preferences.get({ key: 'user' });
    return res?.value ? JSON.parse(res.value) : null;
  } catch (e) {
    console.error('Error while getting user data');
    return null;
  }
};

export const setLanguageStorage = async (language: ILanguage): Promise<void> => {
  try {
    await Preferences.set({ key: 'language', value: JSON.stringify(language) });
  } catch (e) {
    console.error('Error while saving language data');
  }
};

export const getLanguageStorage = async (): Promise<ILanguage | null> => {
  try {
    const res = await Preferences.get({ key: 'language' });
    return res?.value ? JSON.parse(res.value) : null;
  } catch (e) {
    console.error('Error while getting language data');
    return null;
  }
};
