import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './Navbar.css';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MyBackButton } from '../../components/shared/MyBackButton';
import { LogoutButton } from './LogoutButton';

const screens: Record<string, { title: string }> = {
  clients: {
    title: 'USERS',
  },
  expenses: {
    title: 'EXPENSES',
  },
  updates: {
    title: 'UPDATES',
  },
  documents: {
    title: 'DOCUMENTS',
  },
  types: {
    title: 'TYPES',
  },
  notifications: {
    title: 'NOTIFICATIONS',
  },
  payments: {
    title: 'PAYMENTS',
  },
  'expired-expenses': {
    title: 'EXPIRED_EXPENSE_NOTIFICATIONS',
  },
  stats: {
    title: 'ACTIVE_STATS',
  },
};

interface Props {
  children?: JSX.Element;
}

const Navbar: React.FC<Props> = ({ children }: Props) => {
  let title = 'HOME';
  const { t } = useTranslation();
  const location = useLocation();
  const path = location?.pathname?.split('/')[1];
  title = screens?.[path]?.title || title;

  return (
    <>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <MyBackButton />
          </IonButtons>
          <IonTitle className="nav-title">{t(`TABS.${title}`)}</IonTitle>
          <IonButtons slot="end">
            <LogoutButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">*/}
        {/*  <IonToolbar>*/}
        {/*    <IonTitle size="large">{t(`TABS.${title}`)} 1</IonTitle>*/}
        {/*  </IonToolbar>*/}
        {/*</IonHeader>*/}
        {children}
      </IonContent>
    </>
  );
};

export default Navbar;
