import * as React from 'react';
import { SORT_BY_UPDATES, SORT_DIRECTION } from '../../interfaces/enumerables';
import { useUpdates } from '../../api/hooks/updates/useUpdates';
import { UpdateItemCard } from './components/UpdateItemCard';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { getUpdatesSortByFilter } from '../../utils/data';

export const UpdatesScreen = () => {
  const updatesSortByFilter = getUpdatesSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useUpdates}
      ItemCardData={UpdateItemCard}
      sortByFilter={updatesSortByFilter}
      sortByDefaultKey={SORT_BY_UPDATES.PUB_DATE}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
