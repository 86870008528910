import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HomeScreen } from '../screens/Home/HomeScreen';
import { ClientsScreen } from '../screens/Clients/ClientsScreen';
import { ExpensesScreen } from '../screens/Expenses/ExpensesScreen';
import { UpdatesScreen } from '../screens/Updates/UpdatesScreen';
import { DocumentsScreen } from '../screens/Documents/DocumentsScreen';
import { TypesScreen } from '../screens/Types/TypesScreen';
import { NotificationsScreen } from '../screens/Notifications/NotificationsScreen';
import { PaymentsScreen } from '../screens/Payments/PaymentsScreen';
import { ExpiredPaymentsScreen } from '../screens/ExpiredPayments/ExpiredPaymentsScreen';
import { StatsTypesScreen } from '../screens/StatsTypes/StatsTypesScreen';
import { EmailsScreen } from '../screens/Emails/EmailsScreen';
import { ClientScreen } from '../screens/Clients/ClientScreen';
import { UpdateScreen } from '../screens/Updates/UpdateScreen';
import { TypeScreen } from '../screens/Types/TypeScreen';
import { PaymentScreen } from '../screens/Payments/PaymentScreen';
import { NotificationScreen } from '../screens/Notifications/NotificationScreen';
import { ExpiredPaymentScreen } from '../screens/ExpiredPayments/ExpiredPaymentScreen';
import { ExpenseScreen } from '../screens/Expenses/ExpenseScreen';
import { EmailScreen } from '../screens/Emails/EmailScreen';
import { StatsTypeScreen } from '../screens/StatsTypes/StatsTypeScreen';
import { SettingsScreen } from '../screens/Settings/SettingsScreen';
import { DocumentScreen } from '../screens/Documents/DocumentScreen';
import Navbar from './Navbar/Navbar';
import { PATHS } from '../utils/data';
import { selectCurrentLanguage } from '../store/user/userSlice';
import { useAppSelector } from '../store/hooks';
import 'moment/locale/el';
import { ConfigProvider } from 'antd';
import { CompaniesScreen } from '../screens/Companies/CompaniesScreen';
import { CompanyScreen } from '../screens/Companies/CompanyScreen';

export const NavigationContainer = () => {
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  return (
    <ConfigProvider locale={currentLanguage?.antd}>
      <Navbar>
        <Switch>
          <Route path={PATHS.HOME} exact={true} component={HomeScreen} />
          <Route path={PATHS.CLIENTS} exact={true} component={ClientsScreen} />
          <Route path={`${PATHS.CLIENTS}/:id`} exact={true} component={ClientScreen} />
          <Route path={PATHS.COMPANIES} exact={true} component={CompaniesScreen} />
          <Route path={`${PATHS.COMPANIES}/:id`} exact={true} component={CompanyScreen} />
          <Route path={PATHS.EXPENSES} exact={true} component={ExpensesScreen} />
          <Route path={`${PATHS.EXPENSES}/:id`} exact={true} component={ExpenseScreen} />
          <Route path={PATHS.UPDATES} exact={true} component={UpdatesScreen} />
          <Route path={`${PATHS.UPDATES}/:id`} exact={true} component={UpdateScreen} />
          <Route path={PATHS.DOCUMENTS} exact={true} component={DocumentsScreen} />
          <Route path={`${PATHS.DOCUMENTS}/:id`} exact={true} component={DocumentScreen} />
          <Route path={PATHS.TYPES} exact={true} component={TypesScreen} />
          <Route path={`${PATHS.TYPES}/:id`} exact={true} component={TypeScreen} />
          <Route path={PATHS.NOTIFICATIONS} exact={true} component={NotificationsScreen} />
          <Route path={`${PATHS.NOTIFICATIONS}/:id`} exact={true} component={NotificationScreen} />
          <Route path={PATHS.PAYMENTS} exact={true} component={PaymentsScreen} />
          <Route path={`${PATHS.PAYMENTS}/:id`} exact={true} component={PaymentScreen} />
          <Route path={PATHS.EXPIRED_EXPENSES} exact={true} component={ExpiredPaymentsScreen} />
          <Route path={`${PATHS.EXPIRED_EXPENSES}/:id`} exact={true} component={ExpiredPaymentScreen} />
          <Route path={PATHS.STATS} exact={true} component={StatsTypesScreen} />
          <Route path={`${PATHS.STATS}/:id`} exact={true} component={StatsTypeScreen} />
          <Route path={PATHS.EMAILS} exact={true} component={EmailsScreen} />
          <Route path={`${PATHS.EMAILS}/:id`} exact={true} component={EmailScreen} />
          <Route path={PATHS.SETTINGS} component={SettingsScreen} />
          <Route path="*">
            <Redirect to={PATHS.HOME} />
          </Route>
        </Switch>
      </Navbar>
    </ConfigProvider>
  );
};
