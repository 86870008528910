import {
  SORT_BY_CLIENTS,
  SORT_BY_COMPANIES,
  SORT_BY_DOCS,
  SORT_BY_EMAIL,
  SORT_BY_EXPENSES,
  SORT_BY_EXPIRED_PAYMENTS,
  SORT_BY_NOTIFICATIONS,
  SORT_BY_PAYMENTS,
  SORT_BY_STAT,
  SORT_BY_TYPE_OF,
  SORT_BY_UPDATES,
} from '../interfaces/enumerables';
import i18next from 'i18next';
import { ISortBy } from '../interfaces/shared.interface';

export enum PATHS {
  HOME = '/',
  CLIENTS = '/clients',
  COMPANIES = '/companies',
  EXPENSES = '/expenses',
  UPDATES = '/updates',
  DOCUMENTS = '/documents',
  TYPES = '/types',
  NOTIFICATIONS = '/notifications',
  PAYMENTS = '/payments',
  EXPIRED_EXPENSES = '/expired-expenses',
  STATS = '/stats',
  EMAILS = '/emails',
  SETTINGS = '/settings',
  CREATE_SUB_PATH = 'create',
}

export enum ACTION_OF_NOTIFICATION {
  UNPAID_EXPENSES = 0,
  ANNOUNCEMENT = 1,
  DOCS = 2,
  INFO = 3,
  CHARTS = 4,
  VAT = 5,
}

export const TRANSLATION_NOT_MAP: { [key: number]: { translationKey: string } } = {
  [ACTION_OF_NOTIFICATION.ANNOUNCEMENT]: { translationKey: 'TO_ALL_CATEGORY' },
  [ACTION_OF_NOTIFICATION.UNPAID_EXPENSES]: { translationKey: 'TO_UNPAID' },
  [ACTION_OF_NOTIFICATION.DOCS]: { translationKey: 'TO_DOCS' },
  [ACTION_OF_NOTIFICATION.INFO]: { translationKey: 'TO_INFO' },
  [ACTION_OF_NOTIFICATION.CHARTS]: { translationKey: 'TO_CHARTS' },
  [ACTION_OF_NOTIFICATION.VAT]: { translationKey: 'TO_VAT' },
};

export const getCompaniesSortByFilter: () => { [key in SORT_BY_COMPANIES]: ISortBy } = () => {
  return {
    [SORT_BY_COMPANIES.ID]: { value: SORT_BY_COMPANIES.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_COMPANIES.TITLE]: { value: SORT_BY_COMPANIES.TITLE, label: i18next.t('FILTERS.TITLE') },
    [SORT_BY_COMPANIES.EMAIL]: { value: SORT_BY_COMPANIES.EMAIL, label: i18next.t('FILTERS.EMAIL') },
    [SORT_BY_COMPANIES.CREATED_AT]: { value: SORT_BY_COMPANIES.CREATED_AT, label: i18next.t('FILTERS.CREATED_AT') },
  };
};

export const getClientsSortByFilter: () => { [key in SORT_BY_CLIENTS]: ISortBy } = () => {
  return {
    [SORT_BY_CLIENTS.ID]: { value: SORT_BY_CLIENTS.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_CLIENTS.FIRST_NAME]: { value: SORT_BY_CLIENTS.FIRST_NAME, label: i18next.t('FILTERS.FIRST_NAME') },
    [SORT_BY_CLIENTS.USERNAME]: { value: SORT_BY_CLIENTS.USERNAME, label: i18next.t('FILTERS.USERNAME') },
    [SORT_BY_CLIENTS.EMAIL]: { value: SORT_BY_CLIENTS.EMAIL, label: i18next.t('FILTERS.EMAIL') },
    [SORT_BY_CLIENTS.PUB_DATE]: { value: SORT_BY_CLIENTS.PUB_DATE, label: i18next.t('FILTERS.PUB_DATE') },
  };
};

export const getExpensesSortByFilter: () => { [key in SORT_BY_EXPENSES]: ISortBy } = () => {
  return {
    [SORT_BY_EXPENSES.ID]: { value: SORT_BY_EXPENSES.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_EXPENSES.CLIENT_FIRST_NAME]: {
      value: SORT_BY_EXPENSES.CLIENT_FIRST_NAME,
      label: i18next.t('FILTERS.CLIENT_FIRST_NAME'),
    },
    [SORT_BY_EXPENSES.DEADLINE]: { value: SORT_BY_EXPENSES.DEADLINE, label: i18next.t('FILTERS.DEADLINE') },
    [SORT_BY_EXPENSES.DONE]: { value: SORT_BY_EXPENSES.DONE, label: i18next.t('FILTERS.DONE') },
    [SORT_BY_EXPENSES.DONE_DATE]: { value: SORT_BY_EXPENSES.DONE_DATE, label: i18next.t('FILTERS.DONE_DATE') },
    [SORT_BY_EXPENSES.VALUE]: { value: SORT_BY_EXPENSES.VALUE, label: i18next.t('FILTERS.VALUE') },
    [SORT_BY_EXPENSES.TYPE]: { value: SORT_BY_EXPENSES.TYPE, label: i18next.t('FILTERS.TYPE') },
    [SORT_BY_EXPENSES.PUB_DATE]: { value: SORT_BY_EXPENSES.PUB_DATE, label: i18next.t('FILTERS.PUB_DATE') },
  };
};

export const getUpdatesSortByFilter: () => { [key in SORT_BY_UPDATES]: ISortBy } = () => {
  return {
    [SORT_BY_UPDATES.ID]: { value: SORT_BY_UPDATES.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_UPDATES.CLIENT_FIRST_NAME]: {
      value: SORT_BY_UPDATES.CLIENT_FIRST_NAME,
      label: i18next.t('FILTERS.CLIENT_FIRST_NAME'),
    },
    [SORT_BY_UPDATES.DONE]: { value: SORT_BY_UPDATES.DONE, label: i18next.t('FILTERS.DONE') },
    [SORT_BY_UPDATES.VALUE]: { value: SORT_BY_UPDATES.VALUE, label: i18next.t('FILTERS.VALUE') },
    [SORT_BY_UPDATES.TYPE]: { value: SORT_BY_UPDATES.TYPE, label: i18next.t('FILTERS.TYPE') },
    [SORT_BY_UPDATES.PUB_DATE]: { value: SORT_BY_UPDATES.PUB_DATE, label: i18next.t('FILTERS.PUB_DATE') },
  };
};

export const getDocsSortByFilter: () => { [key in SORT_BY_DOCS]: ISortBy } = () => {
  return {
    [SORT_BY_DOCS.ID]: { value: SORT_BY_DOCS.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_DOCS.TITLE]: { value: SORT_BY_DOCS.TITLE, label: i18next.t('FILTERS.TITLE') },
    [SORT_BY_DOCS.DESCRIPTION]: { value: SORT_BY_DOCS.DESCRIPTION, label: i18next.t('FILTERS.DESCRIPTION') },
    [SORT_BY_DOCS.CLIENT_FIRST_NAME]: {
      value: SORT_BY_DOCS.CLIENT_FIRST_NAME,
      label: i18next.t('FILTERS.CLIENT_FIRST_NAME'),
    },
    [SORT_BY_DOCS.PUB_DATE]: { value: SORT_BY_DOCS.PUB_DATE, label: i18next.t('FILTERS.PUB_DATE') },

  };
};

export const getTypeOfSortByFilter: () => { [key in SORT_BY_TYPE_OF]: ISortBy } = () => {
  return {
    [SORT_BY_TYPE_OF.ID]: { value: SORT_BY_TYPE_OF.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_TYPE_OF.TYPE_OF]: { value: SORT_BY_TYPE_OF.TYPE_OF, label: i18next.t('FILTERS.TYPE') },
  };
};

export const getNotificationsSortByFilter: () => { [key in SORT_BY_NOTIFICATIONS]: ISortBy } = () => {
  return {
    [SORT_BY_NOTIFICATIONS.ID]: { value: SORT_BY_NOTIFICATIONS.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_NOTIFICATIONS.TITLE]: { value: SORT_BY_NOTIFICATIONS.TITLE, label: i18next.t('FILTERS.TITLE') },
    [SORT_BY_NOTIFICATIONS.DESCRIPTION]: {
      value: SORT_BY_NOTIFICATIONS.DESCRIPTION,
      label: i18next.t('FILTERS.DESCRIPTION'),
    },
    [SORT_BY_NOTIFICATIONS.DATE]: { value: SORT_BY_NOTIFICATIONS.DATE, label: i18next.t('FILTERS.DATE') },
    [SORT_BY_NOTIFICATIONS.TYPE]: { value: SORT_BY_NOTIFICATIONS.TYPE, label: i18next.t('FILTERS.TYPE') },
  };
};

export const getPaymentsSortByFilter: () => { [key in SORT_BY_PAYMENTS]: ISortBy } = () => {
  return {
    [SORT_BY_PAYMENTS.ID]: { value: SORT_BY_PAYMENTS.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_PAYMENTS.VALUE]: { value: SORT_BY_PAYMENTS.VALUE, label: i18next.t('FILTERS.VALUE') },
    [SORT_BY_PAYMENTS.PAY_DATE]: { value: SORT_BY_PAYMENTS.PAY_DATE, label: i18next.t('FILTERS.PAY_DATE') },
    [SORT_BY_PAYMENTS.USER]: { value: SORT_BY_PAYMENTS.USER, label: i18next.t('FILTERS.CLIENT_FIRST_NAME') },
  };
};

export const getExpiredPaymentsSortByFilter: () => { [key in SORT_BY_EXPIRED_PAYMENTS]: ISortBy } = () => {
  return {
    [SORT_BY_EXPIRED_PAYMENTS.ID]: { value: SORT_BY_EXPIRED_PAYMENTS.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_EXPIRED_PAYMENTS.EXPENSE]: {
      value: SORT_BY_EXPIRED_PAYMENTS.EXPENSE,
      label: i18next.t('FILTERS.EXPENSE'),
    },
    [SORT_BY_EXPIRED_PAYMENTS.PUB_DATE]: { value: SORT_BY_EXPIRED_PAYMENTS.PUB_DATE, label: i18next.t('FILTERS.DATE') },
    [SORT_BY_EXPIRED_PAYMENTS.USER]: {
      value: SORT_BY_EXPIRED_PAYMENTS.USER,
      label: i18next.t('FILTERS.CLIENT_FIRST_NAME'),
    },
  };
};

export const getStatsSortByFilter: () => { [key in SORT_BY_STAT]: ISortBy } = () => {
  return {
    [SORT_BY_STAT.ID]: { value: SORT_BY_STAT.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_STAT.ACTIVE]: { value: SORT_BY_STAT.ACTIVE, label: i18next.t('FILTERS.ACTIVE') },
    [SORT_BY_STAT.TYPE_OF]: { value: SORT_BY_STAT.TYPE_OF, label: i18next.t('FILTERS.TYPE') },
  };
};

export const getEmailSortByFilter: () => { [key in SORT_BY_EMAIL]: ISortBy } = () => {
  return {
    [SORT_BY_EMAIL.ID]: { value: SORT_BY_EMAIL.ID, label: i18next.t('FILTERS.ID') },
    [SORT_BY_EMAIL.PUB_DATE]: { value: SORT_BY_EMAIL.PUB_DATE, label: i18next.t('FILTERS.DATE') },
    [SORT_BY_EMAIL.DATE_NOTIFICATION]: {
      value: SORT_BY_EMAIL.DATE_NOTIFICATION,
      label: i18next.t('FILTERS.NOTIFICATION_MONTH'),
    },
  };
};
