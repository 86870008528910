import * as React from 'react';
import { useEffect, useState } from 'react';
import { MainNavigator } from './MainNavigator';
import { LoadingScreen } from '../components/shared/loading/LoadingScreen';
import { useAppDispatch } from '../store/hooks';
import { loadLangAsync, loadUserAsync } from '../store/user/userActions';
import { sleep } from '../utils/utils';

export const RootNavigator = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const init = async () => {
      // TOOD: use one loadDataAsync function
      await dispatch(loadUserAsync());
      await dispatch(loadLangAsync());
      await sleep(1000);
      setIsLoading(false);
    };

    init();
  }, []);

  return isLoading ? <LoadingScreen /> : <MainNavigator />;
};
