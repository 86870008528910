import * as React from 'react';

interface Props {
  id: number;
  field: string;
  field2?: string;
  onClick: () => any;
}

export const SelectItemCard = ({ id, field, field2, onClick }: Props) => {
  return (
    <div className="flex-space-between-center-row-wrap width-100 card itemOfList" onClick={onClick}>
      <div className="normalText4 flex-start-center-row-wrap">
        <div className="margin-r-12">
          ({id})
        </div>
        <div>
          {field} {field2}
        </div>
      </div>
    </div>
  );
};
