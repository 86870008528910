import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetCompanies } from '../../../interfaces/companies.interface';

const getCompanies = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
  staticParam?: string | boolean | number;
}): Promise<IGetCompanies> => {
  const resp = await apiRQ.get<IGetCompanies>('/admin/companies', { params });

  return resp.data;
};

export const useCompanies = (
  page: number,
  sortrBy = 'id',
  order = 'ASC',
  search: string,
  staticParam: { [key: string]: any } = {},
) => {
  return useQuery<IGetCompanies, Error>(['getCompanies', page, sortrBy, order, search, staticParam], () =>
    getCompanies({ page, orderBy: sortrBy, order, search: search, ...staticParam }),
  );
};
