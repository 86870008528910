import * as React from 'react';
import { IUserClient } from '../../../interfaces/auth.interface';
import { ItemCard } from '../../../components/shared/ItemCard/ItemCard';
import { ItemCardField } from '../../../components/shared/ItemCard/ItemCardField';
import { PATHS } from '../../../utils/data';
import { NotificationEligibility } from './NotificationEligibility';

interface Props {
  data: IUserClient;
}

export const ClientItemCard = ({ data }: Props) => {
  return (
    <ItemCard routerLink={`${PATHS.CLIENTS}/${data.id}`} entityId={data.id}>
      <>
        <NotificationEligibility numberOfTokens={data?.notifications?.length || 0} />
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24" width="width-180px" value={`${data?.firstName} ${data?.lastName}`} />
          <div className="margin-r-24 width-120px">{data?.user?.username}</div>
          <ItemCardField width="width-180px" value={data?.email || ''} />
        </div>
      </>
    </ItemCard>
  );
};
