import * as React from 'react';

interface Props {
  type: string;
}

export const TypePill = ({ type = '' }: Props) => {
  return (
    <div className="flex-center-center-column-nowrap" style={{ position: 'relative' }}>
      <div className="text-shade normalText3" style={{ position: 'absolute', top: -20 }}>{type}</div>
    </div>
  );
};
