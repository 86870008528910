import * as React from 'react';
import { CSSProperties, useCallback } from 'react';
import { IonIcon } from '@ionic/react';
import { notificationsOffOutline, notificationsOutline } from 'ionicons/icons';

interface Props {
  numberOfTokens: number;
  alignCenter?: boolean;
}

export const NotificationEligibility = ({ numberOfTokens, alignCenter }: Props) => {
  const getClassName = useCallback(() => {
    if (alignCenter) {
      return { mainClass: 'flex-center-center-column-nowrap', iconStyle: { position: 'absolute', top: 2 } };
    }

    return { mainClass: 'flex-end-center-row-nowrap', iconStyle: { position: 'absolute', top: -18, right: -10 } };
  }, [alignCenter]);

  const getIcon = useCallback(() => {
    if (numberOfTokens) return { icon: notificationsOutline, color: 'success' };

    return { icon: notificationsOffOutline, color: 'danger' };
  }, [numberOfTokens]);

  return (
    <div className={getClassName().mainClass} style={{ position: 'relative' }}>
      <div className="text-shade normalText3" style={getClassName().iconStyle as CSSProperties}>
        <IonIcon icon={getIcon().icon} style={{ fontSize: 18 }} color={getIcon().color}></IonIcon>
      </div>
    </div>
  );
};
