import * as React from 'react';
import { useClients } from '../../api/hooks/clients/useClients';
import { ClientItemCard } from './components/ClientItemCard';
import { getClientsSortByFilter } from '../../utils/data';
import { SORT_BY_CLIENTS } from '../../interfaces/enumerables';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';

export const ClientsScreen = () => {
  const clientsSortByFilter = getClientsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useClients}
      ItemCardData={ClientItemCard}
      sortByFilter={clientsSortByFilter}
      sortByDefaultKey={SORT_BY_CLIENTS.ID}
    />
  );
};
