import * as React from 'react';
import { useCallback } from 'react';
import { ISortBy } from '../../../interfaces/shared.interface';
import { SORT_DIRECTION } from '../../../interfaces/enumerables';
import { IonButton, IonFabButton, IonIcon, IonSearchbar, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../utils/data';

interface Props {
  sortBy: ISortBy;
  setSortBy: (sortBy: ISortBy) => void;
  sortDirection: SORT_DIRECTION;
  setSortDirection: (sortDirection: SORT_DIRECTION) => void;
  search: string;
  setSearch: (search: string) => void;
  sortByFilterData: { [key: string]: ISortBy };
  hideCreateButton?: boolean;
  hideSearchButton?: boolean;
}

export const Filters = ({
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  setSearch,
  sortByFilterData,
  hideCreateButton,
  hideSearchButton,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const addNew = () => {
    history.push(`${history.location.pathname}/${PATHS.CREATE_SUB_PATH}`);
  };

  const onSortByChange = (event: any) => {
    setSortBy(sortByFilterData[event.detail.value]);
  };

  const toggleSortDirection = () => {
    if (sortDirection === SORT_DIRECTION.ASC) {
      setSortDirection(SORT_DIRECTION.DESC);
      return;
    }

    setSortDirection(SORT_DIRECTION.ASC);
  };

  const getSortIcon = useCallback(() => {
    if (sortDirection === SORT_DIRECTION.DESC) return arrowDownOutline;

    return arrowUpOutline;
  }, [sortDirection]);

  const handleSearchChange = (ev: any) => {
    const value = ev?.detail?.value || '';
    setSearch(value);
  };

  const getSelectOptions = () => {
    return Object.keys(sortByFilterData).map((sortByKey: any) => {
      const val = sortByFilterData[sortByKey].value;
      return (
        <IonSelectOption key={val} value={val}>
          {sortByFilterData[sortByKey].label}
        </IonSelectOption>
      );
    });
  };

  const getCreateButton = () => {
    if (hideCreateButton) {
      return null;
    }

    return (
      <div className="margin-r-6 margin-t-16">
        <IonButton fill="solid" shape="round" onClick={addNew}>
          {t('SHARED.ADD')}
        </IonButton>
      </div>
    );
  };

  const getSearchInput = () => {
    if (hideSearchButton) return null;

    return (
      <IonSearchbar
        mode="md"
        debounce={200}
        className="g-search"
        placeholder={t('FILTERS.SEARCH')}
        onIonChange={handleSearchChange}
      />
    );
  };

  return (
    <div className="flex-space-between-center-row-wrap margin-b-10">
      <div className="flex-start-center-row-wrap">
        {getCreateButton()}
        <div className="margin-r-6 margin-t-16">
          <IonSelect
            mode="ios"
            interface="action-sheet"
            value={sortBy.value}
            placeholder={t('FILTERS.SORT_BY')}
            cancelText={t('SHARED.CANCEL')}
            onIonChange={onSortByChange}>
            {getSelectOptions()}
          </IonSelect>
        </div>
        <div className="margin-t-16">
          <IonFabButton size="small" onClick={toggleSortDirection}>
            <IonIcon icon={getSortIcon()} size="small" />
          </IonFabButton>
        </div>
      </div>
      <div className="margin-t-16">
        {/*<Search search={search} setSearch={setSearch} />*/}
        {getSearchInput()}
      </div>
    </div>
  );
};
