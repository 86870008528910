import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetUpdates } from '../../../interfaces/updates.interface';

const getUpdates = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetUpdates> => {
  const resp = await apiRQ.get<IGetUpdates>('/admin/updates', { params });

  return resp.data;
};

export const useUpdates = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetUpdates, Error>(['getUpdates', page, orderBy, order, search], () =>
    getUpdates({ page, orderBy, order, search }),
  );
};
