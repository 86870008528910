import * as React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { availableLanguagesObj, LANGUAGES } from '../../../config/i18n/common';
import { selectCurrentLanguage } from '../../../store/user/userSlice';
import { setLanguageAsync } from '../../../store/user/userActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const LanguagePicker = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectCurrentLanguage);

  const onChange = (event: any) => {
    const langToSet = availableLanguagesObj[event.detail.value as LANGUAGES];

    if (langToSet) dispatch(setLanguageAsync(langToSet));
  };

  const getSelectOptions = () => {
    return Object.entries(availableLanguagesObj).map(([key, lang]) => {
      return (
        <IonSelectOption key={key} value={lang.value}>
          {lang.label}
        </IonSelectOption>
      );
    });
  };

  return (
    <div className="info-card flex-center-center-column-nowrap">
      <div className="boldText7 margin-b-24">{t('SETTINGS.SELECT_LANG')}</div>

      <div className="width-180px">
        <IonSelect
          color="light"
          mode="ios"
          interface="action-sheet"
          value={language.value}
          cancelText={t('SHARED.CANCEL')}
          onIonChange={onChange}>
          {getSelectOptions()}
        </IonSelect>
      </div>
    </div>
  );
};
