import { IGenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';

const updateCreateNotification = async (variables: any): Promise<IGenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<IGenericResponse>('/admin/notifications/', { data: variables });

  return resp.data;
};

export const useUpdateCreateNotification = () => {
  return useMutation<IGenericResponse, Error>(updateCreateNotification);
};

const deleteNotification = async (data: any): Promise<IGenericResponse> => {
  const resp = await apiRQ.delete<IGenericResponse>('/admin/notifications/', { data });

  return resp.data;
};

export const useDeleteNotification = () => {
  return useMutation<IGenericResponse, Error>(deleteNotification);
};