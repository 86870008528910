import * as React from 'react';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetPaginationData, ISortBy } from '../../../interfaces/shared.interface';
import { SORT_DIRECTION } from '../../../interfaces/enumerables';
import { Filters } from './Filters';
import { Pagination } from 'antd';
import { GeotrianLoading } from '../loading/GeotrianLoading';
import { UseQueryResult } from 'react-query';
import { DataPlaceholder } from '../DataPlaceholder';
import { images } from '../../../theme/assets/img';
import { getPaginationLayoutStyle } from '../../../utils/helpers';

interface Props<T, Tf extends { [key: string]: ISortBy }> {
  useDataHook: (
    page: number,
    sortByValue: string,
    sortDirection: SORT_DIRECTION,
    search: string,
    staticParam?: { [key: string]: any },
  ) => UseQueryResult<IGetPaginationData<T>, Error>;
  ItemCardData: ComponentType<{ key: number; data: T }>;
  sortByFilter: Tf;
  sortByDefaultKey: keyof Tf;
  sortDirectionValue?: SORT_DIRECTION;
  hideCreateButton?: boolean;
  hideSearchButton?: boolean;
  staticParam?: { [key: string]: any };
  specificLayoutClass?: string;
  initSearchValue?: string;
}

export const EntityPaginationData = <T extends { id: number }, Tf extends { [key: string]: ISortBy }>({
  useDataHook,
  ItemCardData,
  sortByFilter,
  sortByDefaultKey,
  sortDirectionValue = SORT_DIRECTION.ASC,
  hideCreateButton,
  hideSearchButton,
  staticParam,
  specificLayoutClass,
  initSearchValue,
}: Props<T, Tf>) => {
  const { t } = useTranslation();
  const layoutClass = getPaginationLayoutStyle(specificLayoutClass);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<ISortBy>(sortByFilter[sortByDefaultKey]);
  const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(sortDirectionValue);
  const [search, setSearch] = useState<string>(initSearchValue || '');
  const { isLoading, data, isError, error } = useDataHook(page, sortBy.value, sortDirection, search, staticParam);

  const showTotal = (total: number) => {
    return t('SHARED.TOTAL_PAGINATION', { total });
  };

  const updateSearch = (searchString: string) => {
    setSearch(searchString);
    setPage(1);
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
  };

  const renderList = () => {
    if (data && data?.data?.length > 0) {
      return data.data.map((item) => {
        return <ItemCardData key={item.id} data={item} />;
      });
    }
  };

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="filter-page">
        <div className="width-100 list-padding-horizontal">
          <Filters
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            search={search}
            setSearch={updateSearch}
            sortByFilterData={sortByFilter}
            hideCreateButton={hideCreateButton}
            hideSearchButton={hideSearchButton}
          />
        </div>
        {data && data?.data?.length > 0 && (
          <div>
            <div className={`list-items-itemOfList padding-t-10 padding-b-10 list-padding-horizontal ${layoutClass}`}>
              {renderList()}
            </div>
            <div className="width-100 margin-t-24 flex-end-center-row-nowrap list-padding-horizontal">
              <Pagination
                size="small"
                current={page}
                total={data?.total || 0}
                defaultPageSize={20}
                showSizeChanger={false}
                showTotal={showTotal}
                onChange={changePage}
                hideOnSinglePage={true}
              />
            </div>
          </div>
        )}
        {isError && <DataPlaceholder imgSource={images.somethingWentWrong} msg={'SHARED.SOMETHING_WENT_WRONG'} />}
        {data?.data?.length === 0 && <DataPlaceholder imgSource={images.noData} msg={'SHARED.NO_DATA'} />}
      </div>
      {isLoading && <GeotrianLoading />}
    </div>
  );
};
