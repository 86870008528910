import * as React from 'react';
import './ItemCard.scss';
import { useHistory } from 'react-router-dom';
import { EntityIdInCard } from './EntityIdInCard';

interface Props {
  children: JSX.Element;
  classes?: string;
  routerLink?: string;
  entityId?: number | string;
}

export const ItemCard = ({ children, classes = '', routerLink = '', entityId = '' }: Props) => {
  const history = useHistory();

  const click = () => {
    if (routerLink) {
      history.push(routerLink);
    }
  };

  return (
    <div className={`card itemOfList ${classes}`} onClick={click}>
      <EntityIdInCard id={entityId} />
      {children}
    </div>
  );
};
