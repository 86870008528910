import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IGetActiveStats } from '../../../interfaces/stats.interface';

const getActiveStats = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetActiveStats> => {
  const resp = await apiRQ.get<IGetActiveStats>('/admin/stats/active', { params });

  return resp.data;
};

export const useActiveStats = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetActiveStats, Error>(['getActiveStats', page, orderBy, order, search], () =>
    getActiveStats({ page, orderBy, order, search }),
  );
};
