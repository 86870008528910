import * as React from 'react';
import { ExpiredPaymentItemCard } from './components/ExpiredPaymentItemCard';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { useExpiredPayments } from '../../api/hooks/expiredPayments/useExpiredPayments';
import { SORT_BY_EXPIRED_PAYMENTS, SORT_DIRECTION } from '../../interfaces/enumerables';
import { getExpiredPaymentsSortByFilter } from '../../utils/data';

export const ExpiredPaymentsScreen = () => {
  const expiredPaymentsSortByFilter = getExpiredPaymentsSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useExpiredPayments}
      ItemCardData={ExpiredPaymentItemCard}
      sortByFilter={expiredPaymentsSortByFilter}
      sortByDefaultKey={SORT_BY_EXPIRED_PAYMENTS.ID}
      sortDirectionValue={SORT_DIRECTION.DESC}
      hideCreateButton={true}
    />
  );
};
