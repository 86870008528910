import * as React from 'react';
import { EntityPaginationData } from '../../components/shared/pagination/EntityPaginationData';
import { useEmails } from '../../api/hooks/email/useEmails';
import { EmailNewExpensesItemCard } from './components/EmailNewExpensesItemCard';
import { SORT_BY_EMAIL } from '../../interfaces/enumerables';
import { getEmailSortByFilter } from '../../utils/data';

// Automatic email to selected users to inform them about new expenses availability
export const EmailsScreen = () => {
  const emailSortByFilter = getEmailSortByFilter();

  return (
    <EntityPaginationData
      useDataHook={useEmails}
      ItemCardData={EmailNewExpensesItemCard}
      sortByFilter={emailSortByFilter}
      sortByDefaultKey={SORT_BY_EMAIL.ID}
    />
  );
};
