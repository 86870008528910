import { useQuery } from 'react-query';
import { apiRQ } from '../../api';
import { IGetDocuments } from '../../../interfaces/documents.inteface';

const getDocuments = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetDocuments> => {
  const resp = await apiRQ.get<IGetDocuments>('/admin/documents', { params });

  return resp.data;
};

export const useDocs = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetDocuments, Error>(['getDocuments', page, orderBy, order, search], () =>
    getDocuments({ page, orderBy, order, search }),
  );
};
