import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { IGetEmails } from '../../../interfaces/emails.interface';

const getEmails = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<IGetEmails> => {
  const resp = await apiRQ.get<IGetEmails>('/admin/emails', { params });

  return resp.data;
};

export const useEmails = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<IGetEmails, Error>(['getEmails', page, orderBy, order, search], () =>
    getEmails({ page, orderBy, order, search }),
  );
};